import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
  Typography
} from "@mui/material";
import {Navigate, useNavigate } from 'react-router-dom';
import './login.css';
import { login } from "../../slices/auth";
import logo from '../../assets/images/logo.png';
import { clearMessage } from '../../slices/message';

const Login = ({setLoader}) => {

    const navigate = useNavigate();

    const { isLoggedIn } = useSelector((state) => state.auth);
    
    const dispatch = useDispatch();

    const [userCredentials, setUserCredentials] = useState({
        username: "",
        password: ""
      });
    
    const [showpassword, setShowpassword] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true)
        const urlEncodedData = new URLSearchParams(userCredentials).toString();
        dispatch(login(urlEncodedData))
        .unwrap()
        .then(() => {
          navigate("/");
          setLoader(false);
        })
        .catch(()=>{
          setLoader(false);
        })
      };
    
      const handleClickShowPassword = () => {
        setShowpassword(!showpassword);
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

      if (isLoggedIn) {
        return <Navigate to="/" />;
      }

  return (
    <Box className='login_bg' sx={{ height:'100vh' }}>
    <Box
    component="form"
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}
    sx={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
        <Paper sx={{width: 400, p: {xs: 3, md: 5}, textAlign:'center', boxShadow:'none', borderRadius: 3}}>
            <img src={logo} alt='logo' width={200}/>
            <Grid container spacing={2} sx={{marginTop: 1}}>
                <Grid item xs={12}>
                <Typography variant='body1' sx={{fontWeight:'normal', textAlign:'start', mb: 0.5}}>Username</Typography>
                    <TextField fullWidth id="outlined-basic" placeholder="Enter your username" variant="outlined" 
                    onChange={(e) =>
                      setUserCredentials({
                        ...userCredentials,
                        username: e.target.value,
                      })
                    }
                    />
                </Grid>
                <Grid item xs={12}>
                <Typography variant='body1' sx={{fontWeight:'normal', textAlign:'start', mb: 0.5}}>Password</Typography>
                <FormControl sx={{ width: "100%" }} variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showpassword ? "text" : "password"}
                      value={userCredentials.password}
                      onChange={(e) =>
                        setUserCredentials({
                          ...userCredentials,
                          password: e.target.value,
                        })
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showpassword ? <Visibility sx={{color:'primary'}}/> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="Enter your password"
                    />
                  </FormControl>                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth type='submit' variant='contained' size='large' sx={{py: 2, marginTop: 1}}>Login</Button>
                </Grid>
            </Grid>
        </Paper>
    </Box>
    </Box>
  )
}

export default Login