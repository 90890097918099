import { Container, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import SettingsTab from '../../components/tabs/SettingsTab';

const Settings = ({ setLoader }) => {
  useEffect(() => {
    setLoader(true); // Optional: If you have a global loader
    return () => setLoader(false); // Cleanup loader on unmount
  }, []);
  const handleApiCallComplete = () => {
    setLoader(false); // Stop global loader if applicable
  };
  return (
    <Container className='content' maxWidth="fluid">
      <SettingsTab setLoader={setLoader} handleApiCallComplete={handleApiCallComplete} />
    </Container>
  )
}

export default Settings;