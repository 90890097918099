import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Button, Container } from '@mui/material';
import BasicTabs from '../../components/tabs/Tabs2';
import { getnews, getnewsstories, upnews } from '../../slices/news';

const NewsStories = ({setLoader}) => {

  const [page, setPage] = useState(0);

  const {newsStories: newsData} = useSelector((state) => state.news);

  const dispatch = useDispatch();

  //console.log(newsData);

  useEffect(()=>{
      setLoader(true);
      const formData = {
        "filter": {},
        "sort": "_id",
        "order": 1,
        "page": page,
        "page_size": 10
      }
      if(page === 0){
        dispatch(getnewsstories(formData))
        .then(() => {
          setLoader(false);
        })
        .catch(()=>{
          setLoader(false);
        })
      }
      else{
        dispatch(upnews(page))
        .then(() => {
          setLoader(false);
        })
        .catch(()=>{
          setLoader(false);
        })
      }
  }, [page])
  
  const updateNews = () =>{
    setPage(page+1);
  }

  return (
    <Container className='content' maxWidth="fluid">
      <BasicTabs newsData={newsData ? newsData : []} setLoader={setLoader} />
        <Link to='/newsstories/addstory'>
        <Button sx={{position:'absolute', top: 100, right: 30}} variant='contained'>New Story</Button>
        </Link>
        <Button variant='contained' onClick={updateNews}>Load More</Button>
    </Container>
  )
}

export default NewsStories