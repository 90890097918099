import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { styled, alpha } from '@mui/material/styles';
import { Button, Container, List, ListItem, ListItemText, Paper,Typography, Autocomplete, Box, TextField } from '@mui/material';
import BasicTabs from '../../components/tabs/Tabs1';
import { getnews, getAdminStats, upnews, getcategories, getcity } from '../../slices/news';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import newsService from '../../services/news.service';
import MediaCard from '../../components/card/Card';
import { setStatus } from '../../slices/Status';

import dayjs from 'dayjs';

const Search = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 100,
  right: 150,
  borderRadius: theme.shape.borderRadius,
  border: '1px solid #000',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#000',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1.5, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '25ch',
    },
  },
}));

const News = ({ setLoader }) => {
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [newstype, setNewstype] = useState("short");
  const status = useSelector((state) => state.status);
  const [query, setQuery] = useState();
  const { categories: catData } = useSelector((state) => state.news);
  const [category, setCategory] = useState(null);
  const [city, setCity] = useState(null);  
  const { cities: cityData } = useSelector((state) => state.news);
  const { news: newsData } = useSelector((state) => state.news);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [filteredNews, setFilteredNews] = useState([]);
  const [selectedNews, setSelectedNews] = useState(null);
  const [openPaper, setOpenPaper] = useState(true);
  const [focused, setFocused] = useState(false);  

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // console.log('Filtered News:', filteredNews);
  // console.log('Start Date:', startDate);
  // console.log('End Date:', endDate);

  // console.log("results:",results);

  // const date = new Date(newsData?.created_at);
  // const formattedDate = date.toLocaleDateString('en-GB', {
  //   day: '2-digit',
  //   month: 'short',
  //   year: 'numeric'
  // });
  

  useEffect(() => {
    setLoader(true);
    const params = {
      status: status,
      news_type: newstype,
      page: page,
      limit: 12,
      query: query,
    };
    if (page === 1) {
      dispatch(getcategories(params))
      dispatch(getcity())
      dispatch(getnews(params))
        .then((data) => {
          const updata = data?.payload?.data?.data?.data;
          setResults(updata);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      dispatch(upnews(params))
        .then(() => {
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
    console.log("API Request Params:", params);
  },  [newstype, query, page, dispatch, status, setLoader]);


//   useEffect(() => {
//     const StatusParams = searchParams.get('status');
//     console.log("StatusParams:",StatusParams);
    
//     switch (StatusParams) {
//         case "approved":
//             dispatch(setStatus("approved"));
//             break;
//         case "rejected":
//             dispatch(setStatus("rejected"));
//             break;
//         case "pending":
//             dispatch(setStatus("pending"));
//             break;
//         default:
//             dispatch(setStatus("pending"));
//     }
// }, [dispatch, searchParams]);

useEffect(() => {
  if (Array.isArray(newsData)) {
    const filtered = newsData.filter((news) => {
      if (!news) return false;

      const newsDate = dayjs(news?.created_at);
      // console.log('News Date:', newsDate);
      const matchesStartDate = !startDate || newsDate.isSame(startDate, 'day');
      const matchesEndDate = !endDate || newsDate.isSame(endDate, 'day') || newsDate.isBefore(endDate, 'day');


      const matchesCategory = !category || news.category?.id === category?.id;
      const matchesCity = !city || news.city?.id === city?.id;
      const matchesQuery = query
        ? news.meta_title?.toLowerCase().includes(query.toLowerCase())
        : true;
      const matchesType = newstype ? news.news_type === newstype : true;

      return matchesCategory && matchesCity && matchesQuery && matchesType && matchesStartDate && matchesEndDate;
    });

    setFilteredNews(filtered);
  } else {
    console.error('newsData is not a valid array:', newsData);
  }
}, [newsData, category, city, query, newstype,startDate, endDate]);

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });
};




  const updateNews = () => {
    setPage(page + 1);
  };
  
  
  // useEffect(() => {
  //   console.log("updateNews:", updateNews);
  //   updateNews()
  // },[]);
  useEffect(() => {
    if (query && focused) {
      setOpenPaper(true);
    } else {
      setOpenPaper(false);
    }
  }, [query, focused]);

  const handleInputChange = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
  };

 const handleTabChange = (newTabType) => {
  // console.log("Tab changed to:", newTabType);
  setNewstype(newTabType); // Update the type of news
  setPage(1); // Reset pagination
};

  

  const handleItemClick = (result) => {
    setSelectedNews(result);
    setOpenPaper(false);
    setQuery(result?.meta_title);
    setFocused(false);
  };

  const handleFocus = () => {
    setFocused(true);
  };
  


  return (
    <Container className="content" maxWidth="fluid">
      {/* <BasicTabs setLoader={setLoader} status={status} setStatus={setStatus} setNewstype={setNewstype} newsData={newsData ? newsData : []} />
   
  */}
    

      {/* Pass filteredNews to BasicTabs */}
      <BasicTabs 
        setLoader={setLoader} 
        status={status} 
        setNewstype={handleTabChange} 
        newsData={filteredNews}
        catData={catData}
        category={category}
        setCategory={setCategory}
        cityData={cityData}
        city={city}
        setCity={setCity}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        newstype={newstype}
      />
      

      <Link to="/news/addnews">
        <Button sx={{ position: 'absolute', top: 100, right: 30 }} variant="contained">
          New News
        </Button>
      </Link>
   

      {/* Search Input */}
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          value={query}
          onChange={handleInputChange}
          onFocus={handleFocus} 
          inputProps={{ 'aria-label': 'search' }}
        />
      </Search>
      
      {openPaper && results.length > 0 && (
  <Paper
    sx={{
      position: 'absolute',
      zIndex:"10",
      top: 160,
      right: 150,
      width: '25%',
      maxHeight: '400px', 
      overflowY: 'auto', 
      borderRadius: 2, 
      boxShadow: 3,
      padding: 2, 
      backgroundColor: '#fff',
    }}
  >
    <List>
      {filteredNews.map((result) => (
        <ListItem 
          key={result.id}
          onClick={() => {
            // console.log('Clicked news:', result)
            handleItemClick(result)
          }}
          sx={{
            borderBottom: '1px solid #e0e0e0',
            '&:last-child': { borderBottom: 'none' }, 
            '&:hover': {
              backgroundColor: '#f5f5f5', 
            },
          }}
        >   
          <ListItemText
            primary={
              <Typography variant="subtitle1" fontWeight="bold" color="primary"  sx={{ fontSize: '14px' }} >
                {result?.meta_title}
              </Typography>
            }
            // secondary={
            //   <Typography variant="body2" color="textSecondary">
            //     {result?.meta_description}
            //   </Typography>
            // }
          />
        </ListItem>
      ))}
    </List>
  </Paper>
)}
      <Button variant="contained" onClick={updateNews}>
        Load More
      </Button>
    </Container>
  );
};

export default News;
