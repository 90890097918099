// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  
  #root .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
  }
  
  /* Snow Theme */
  #root .ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }
  
  #root .ql-editor {
    min-height: 18em;
    height: 100%;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/news/styles.css"],"names":[],"mappings":";;EAEE;IACE,gCAAgC;IAChC,iCAAiC;IACjC,mBAAmB;EACrB;;EAEA,eAAe;EACf;IACE,cAAc;IACd,mBAAmB;IACnB,6BAA6B;IAC7B,8BAA8B;EAChC;;EAEA;IACE,gBAAgB;IAChB,YAAY;EACd","sourcesContent":["\n  \n  #root .ql-container {\n    border-bottom-left-radius: 0.5em;\n    border-bottom-right-radius: 0.5em;\n    background: #fefcfc;\n  }\n  \n  /* Snow Theme */\n  #root .ql-snow.ql-toolbar {\n    display: block;\n    background: #eaecec;\n    border-top-left-radius: 0.5em;\n    border-top-right-radius: 0.5em;\n  }\n  \n  #root .ql-editor {\n    min-height: 18em;\n    height: 100%;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
