import React, {useState} from 'react';
import News from './pages/news/News.js';
import { Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import Dashboard from './pages/dashboard/Dashboard.js';
import Editnews from './pages/news/Editnews.js';
import Login from './pages/login/Login.js';
import ProtectedRoute from './utils/ProtectedRoute.js';
import NewsStories from './pages/news/Newsstories.js';
import Watch from './pages/watch/Watch.js';
import Addnews from './pages/news/Addnews.js';
import Addstories from './pages/news/Addstories.js';
import Editstories from './pages/news/Editstories.js';
import Comments from './pages/news/Comments.js';
import Library from './pages/news/Library.js';
import CardEndpoint from './components/cardsEndpoint/CardEndpoint.js';
import Settings from './pages/news/Settings.js';


const RouteRoute = ({setLoader}) => {

  const [show, setShow] = useState(true);

  return (
    <>
    <Routes>
        <Route path='/login' element={<Login setLoader={setLoader}/>}></Route> 
        <Route path='/' element={<Layout show={show} setShow={setShow}/>}>
        <Route path='/' element={<ProtectedRoute><Dashboard show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/news' element={<ProtectedRoute><News  show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/news/addnews' element={<ProtectedRoute><Addnews  show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/news/editnews/:newsid' element={<ProtectedRoute><Editnews setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/news/editnews/:newsid/cards' element={<ProtectedRoute><CardEndpoint  setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/newsstories' element={<ProtectedRoute><NewsStories  show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/newsstories/search' element={<ProtectedRoute><NewsStories  show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/newsstories/addstory' element={<ProtectedRoute><Addstories  show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/newsstories/editstories/:storyid' element={<ProtectedRoute><Editstories setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/watch' element={<ProtectedRoute><Watch show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/comments' element={<ProtectedRoute><Comments  show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/comments/:commentid' element={<ProtectedRoute><Comments  show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/library' element={<ProtectedRoute><Library  show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/settings' element={<ProtectedRoute><Settings show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
      </Route>
    </Routes>
    </>
  )
}

export default RouteRoute;