import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography, IconButton } from '@mui/material';
import { TextField, Chip, Divider, FormLabel, RadioGroup, FormControlLabel, Radio, Container, Modal, Autocomplete,Dialog, DialogActions, DialogContent,DialogTitle, FormGroup } from '@mui/material';
import CloudUpload from '../../components/button/Cloudupload';
import {Grid} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Editor from './Editor';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { deletenews, getcategories, getcity, getnewsad, getnewsbyid, getstates, gettopics,addTopics,updateTopics, updateaction, updatenews, updatepublish, uploadimage,uploadTopicImage,CardPreview,newsTranslate } from '../../slices/news';
import { Update } from '@mui/icons-material';
import CardEndpoint from '../../components/cardsEndpoint/CardEndpoint';
import { setStatus } from '../../slices/Status';
import { toast } from "sonner";

const NewsNameData=[
  { id: 1, name: "International News", value:"is_international_news" },
  { id: 2, name: "National News", value:"is_national_news" },
  { id: 3, name: "State News", value:"is_state_news"},
  { id: 4, name: "Local News",value:"is_local_news" },
]

  

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 2,
  borderRadius: 2
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Editnews({setLoader}) {

  const {newsid,language}= useParams();
  // const [shouldRedirect, setShouldRedirect] = useState(false); 

  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const {categories: catData} = useSelector((state) => state.news);
  

  const {topics: topData} = useSelector((state) => state.news);

  const {newsAd: adData} = useSelector((state) => state.news);

  const {cities: cityData} = useSelector((state) => state.news);

  const {states: stateData} = useSelector((state) => state.news);

  const [newstype, setNewstype] = useState(null);

  const [categoryName, setCategoryName] = React.useState(null);

  const [topicsname, setTopicsName] = React.useState(null);

  const [tanewsad, settaNewsAd] = React.useState(null);

  const [ennewsad, setenNewsAd] = React.useState(null);

  const [cityname, setCityName] = React.useState(null);

  const [statename, setStateName] = React.useState(null);

  // const [metatitle, setMetatitle] = React.useState("");

  const [enMetaTitle,setEnMetaTitle] = React.useState("");

  const [taMetaTitle,setTaMetaTitle] = React.useState("");

  // const [metadesc, setMetadesc] = React.useState("");

  const [enmetadesc, setEnMetadesc] = React.useState("");

  const [tametadesc, setTaMetadesc] = React.useState("");

  const [file1, setFile1] = useState(null);
  

  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);

  // const [inputValue, setInputValue] = useState('');

  const [inputValueTa, setInputValueTa] = useState('');

  const [inputValueEn, setInputValueEn] = useState('');
  
  // const [hashtags, setHashtags] = useState([]);

  const [enHashtags, setenHashtags] = useState([]);

  const [taHashtags, settaHashtags] = useState([]);
  
  const [content1, setContent1] = useState('');

  const [content2, setContent2] = useState('');
  const [cardPrev,setCardPrev] = useState('')

  const [openPopup, setOpenPopup] = useState(false);
  const [newTopic, setNewTopic] = useState(""); 
  const [newsName,setNewsName] = useState('');

  const isStateDisabled = newsName?.name === "International News" || newsName?.name === "National News" || newsName?.name === "Local News";
  const isCityDisabaled =  newsName?.name === "International News" || newsName?.name === "National News" || newsName?.name === "State News";
  const [isFocussed,setIsFocussed] = useState(null);

  // console.log("cardPrev:",cardPrev);


  const [topics, setTopics] = useState([]);
  const [popup, setPopup] = useState(false);
  const [taTopicName, setTaTopicName] = useState('');
  const [enTopicName, setEnTopicName] = useState('');
  const [enTopicDesc, setEnTopicDesc] = useState('');
  const [taTopicDesc, setTaTopicDesc] = useState('');
  const [file, setFile] = useState(null);

  console.log("enTopicName:",enTopicName);
  

  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(null);
 console.log("selectedTopic:",selectedTopic);
  


  const [newsdata, setNewsdata] = useState({
    category_id:"", 
    city_id: "",
    content:{
      ta:{
        headlines: "",
        title: "",
        web_content: "",
        news_ad_id : "",
        meta_description:"",
        meta_title:"",
        tags:taHashtags
      },
      en:{
        headlines: "",
        title: "",
        web_content: "",
        news_ad_id : "",
        meta_description:"",
        meta_title:"",
        tags:enHashtags
      }
    },
    is_breaking: false,
    is_international_news: false,
    is_national_news:false,
    is_state_news: false,
    meta_description: "",
    meta_title: "",
    news_source:{
      name: "",
      url: ""
    },
    news_type: "",
    state_id: "",
    // tags: hashtags,
    topics: [
      enTopicName?.id
    ]
  })
  console.log("Newsdata:",newsdata);

  const selectedLanguage = language === "ta" || language === "en" ? language : "en"; 

  const date = new Date(newsdata?.created_at);
const formattedDate = date.toLocaleDateString('en-GB', {
  day: '2-digit',
  month: 'short',
  year: 'numeric'
});

const formattedTime = date.toLocaleTimeString('en-GB', {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false
});
  

const handleOpen = () => {
  setPopup(true)
}
const handleClose = () => {
  resetFields();
  setPopup(false);
};

const resetFields = () => {
  setTaTopicName('');
  setEnTopicName('');
  setEnTopicDesc('');
  setTaTopicDesc('');
  setFile(null);
  setIsEditMode(false);
};


// useEffect(() => {
//   if (popup) {
//     if (!isEditMode && selectedTopic) {
//       // Set existing data in Edit Mode
//       setEnTopicName({
//         id: selectedTopic?.id || '',
//         name: selectedTopic?.multilingual_names?.en || ''
//       });
//     } else {
//       // Reset fields for Add Mode
//       setEnTopicName({ id: '', name: '' });
//     }
//   }
// }, [popup, isEditMode, selectedTopic]);


  const handleValue = (e, name) => {
    setNewsdata({
      ...newsdata,
      [name]: e.target.value,
    });
    if(name==='tametatitle'){
      setTaMetaTitle(e.target.value)
    }
    if(name==='enmetatitle'){
      setEnMetaTitle(e.target.value)
    }
    if(name==='tametadescription'){
      setTaMetadesc(e.target.value)
    }
    if(name==='enmetadescription'){
      setEnMetadesc(e.target.value)
    }
    if(name==='nsname'){
      setNewsdata({
        ...newsdata,
        news_source:
          {
          ...newsdata?.news_source,
          name: e.target.value
          },
      });
    }
    if(name==='nsurl'){
      setNewsdata({
        ...newsdata,
        news_source:
          {
          ...newsdata?.news_source,
          url: e.target.value
          },
      });
    }
    if(name==='tntitle'){
      setNewsdata({
        ...newsdata,
        content:{
          ...newsdata?.content,
          ta:{
            ...newsdata?.content?.ta,
            title: e.target.value
          }
        }
      });
    }
    if(name==='tnheadlines'){
      setNewsdata({
        ...newsdata,
        content:{
          ...newsdata.content,
          ta:{
            ...newsdata.content.ta,
            headlines: e.target.value
          }
        }
      });
    }
    if(name==='tnshort_summary'){
      setNewsdata({
        ...newsdata,
        content:{
          ...newsdata.content,
          ta:{
            ...newsdata.content.ta,
            short_summary: e.target.value
          }
        }
      });
    }
    if(name==='entitle'){
      setNewsdata({
        ...newsdata,
        content:{
          ...newsdata.content,
          en:{
            ...newsdata.content.en,
            title: e.target.value
          }
        }
      });
    }
    if(name==='enheadlines'){
      // console.log("enheadlines:",e.target.value); 
      setNewsdata({
        ...newsdata,
        content:{
          ...newsdata.content,
          en:{
            ...newsdata.content.en,
            headlines: e.target.value
          }
        }
      });
    }
    if(name==='enshort_summary'){
      setNewsdata({
        ...newsdata,
        content:{
          ...newsdata.content,
          en:{
            ...newsdata.content.en,
            short_summary: e.target.value
          }
        }
      });
    }
  }

  const handleInputChangeEn = (e) => {
    setInputValueEn(e.target.value);
  };
  
  const handleInputChangeTa = (e) => {
    setInputValueTa(e.target.value);
  };

  const handleInputKeyDownEn = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const value = inputValueEn.trim();
      if (value) {
        setenHashtags([...enHashtags, value]);
        setInputValueEn('');
      }
    }
  };
  const handleInputKeyDownTa = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const value = inputValueTa.trim();
       if (value) {
        settaHashtags(prevTags => {
          console.log("Previous Tags: ", prevTags);
          return [...prevTags, value]; // Always return a new array
        });
        setInputValueTa('');
      }
    }
  };


  const handleDeleteChipEn = (index) => {
    const updatedHashtags = [...enHashtags];
    updatedHashtags.splice(index, 1);
    setenHashtags(updatedHashtags);
  };

  const handleDeleteChipTa = (index) => {
    const updatedHashtags = [...taHashtags];
    updatedHashtags.splice(index, 1);
    settaHashtags(updatedHashtags);
  };

  const handleCheckboxChange = (event) => {
    setNewsdata({
      ...newsdata,
      [event.target.name]: event.target.checked,
    });
  };

  const arrowBack = () => {
    navigate(-1);
  };

  useEffect(()=>{
    setLoader(true)
    const formData = {
      "filter": {},
      "sort": "_id",
      "order": 1,
      "page": 0,
      "page_size": 100
    }
      dispatch(getcategories(formData))
      dispatch(gettopics(formData))
      dispatch(getnewsad(formData))
      dispatch(getcity())
      dispatch(getstates())
      // dispatch(CardPreview({ news_id:newsid , language: selectedLanguage }));
      dispatch(getnewsbyid(newsid))
        .then((data) => {
          console.log("Data:",data);
        const upData = data.payload.newsdata.data
        console.log("UpData:",upData);
        setNewsdata(upData);
        // setCardPrev(data?.data)
        setCategoryName(upData?.category);
        // setHashtags(upData.tags);
        setenHashtags(Array.isArray(upData?.content?.en?.tags) ? upData.content.en.tags : []);
        settaHashtags(Array.isArray(upData?.content?.ta?.tags) ? upData.content.ta.tags : []);
        // setMetatitle(upData?.meta_title);
        setTaMetaTitle(upData?.content?.ta?.meta_title);
        setEnMetaTitle(upData?.content?.en?.meta_title);
        // setMetadesc(upData?.meta_description);
        setTaMetadesc(upData?.content?.ta?.meta_description);
        setEnMetadesc(upData?.content?.en?.meta_description);
        setEnTopicName({
          id: upData?.topics[0]?.id,
          name: upData?.topics[0]?.multilingual_names?.en
        });
        setStateName(upData?.state)
        setCityName(upData?.city)
        settaNewsAd(upData?.content?.ta?.news_ad);
        setenNewsAd(upData?.content?.en?.news_ad);
        setNewstype(upData?.news_type);
        setFile1(upData?.news_card_images?.ta?.original_url);
        
        setFile2(upData?.news_card_images?.en?.original_url);
        
        setContent1(upData?.content?.ta?.web_content);
        setContent2(upData?.content?.en?.web_content);
        setLoader(false);
        //console.log(newsdata.images.original_url)
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  useEffect(()=>{
    dispatch(getnewsbyid(newsid))
  },[getnewsbyid])

  const handleTranslate = (newsid) => {
    dispatch(newsTranslate(newsid))
      .then((data) => {
        console.log("newsTranslate:", data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // useEffect(() => {
  //   setLoader(true);
  //   dispatch(CardPreview({ news_id: newsid, language: selectedLanguage }))
  //     .then((response) => {
  //       // console.log("response:", response?.payload?.data);
  //       const data = response?.payload?.data;
  //       let updatedContent = data?.data || '';
  //       if (updatedContent) {
  //         updatedContent = updatedContent
  //           .replace('{{published_date}}', `${formattedDate} ${formattedTime}`)
  //           .replace('{{category}}', newsdata?.category?.name)
  //           .replace('{{city}}', newsdata?.city?.name)
  //           .replace('{{news_headlines}}',newsdata?.content?.[selectedLanguage]?.headlines || '');
  //       }
  
  //       setCardPrev(updatedContent);
  //       setLoader(false);
  //     })
  //     .catch((error) => {
  //       setLoader(false);
  //       console.error("Error fetching data:", error);
  //     });
  // }, [dispatch, newsid, selectedLanguage, newsdata]);
  useEffect(()=>{
    setLoader(true)
    const formData = {
      "filter": {},
      "sort": "_id",
      "order": 1,  
      "page": 0,
      "page_size": 100
    }
      dispatch(getcategories(formData))
      dispatch(gettopics(formData))
      // dispatch(addTopics(formData))
      dispatch(getnewsad(formData))
      dispatch(getcity())
      dispatch(getstates())
      // dispatch(getnewsbyid(params.newsid))
      .then((data) => {
        const upData = data.payload.newsdata.data
        // console.log("upData:",upData);
        
        setNewsdata(upData);
        setCategoryName(upData?.category);
        
        // setTopicsName(newsdata?.topics?.length > 0 && newsdata?.topics[0]?.multilingual_names?.en);
        // setHashtags(upData.tags);
        setenHashtags(upData?.content?.en?.tags);
        settaHashtags(upData?.content?.ta?.tags);
        settaNewsAd(upData?.content?.ta?.news_ad);
        setenNewsAd(upData?.content?.en?.news_ad)
        setFile1(upData?.content?.ta?.images);
        setFile2(upData?.content?.en?.images);
        setContent1(upData?.content?.ta?.web_content);
        setContent2(upData?.content?.en?.web_content);
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  const fetchTopics = () => {
    const formData = {
      filter: {},
      sort: '_id',
      order: 1,
      page: 0,
      page_size: 100,
    };

    dispatch(gettopics(formData))
      .then((data) => {
        const fetchedTopics = data?.payload?.data?.data ;
        setTopics(fetchedTopics?.data);
      })
      .catch((error) => console.error('Error fetching topics:', error));
  };

 
  const handleAddNewTopic = () => {
    if (taTopicName.trim() || enTopicName.trim()) {
      const topicData = {
        multilingual_descriptions: { en: enTopicDesc, ta: taTopicDesc },
        multilingual_names: { en: enTopicName.trim(), ta: taTopicName.trim() },
      };

      if (isEditMode) {
        dispatch(updateTopics({ topic_id: selectedTopic?.id, formData: topicData }))
          .then(() => {
            console.log("selectedTopic:",selectedTopic?.id);
            

            if (file) {
              const formData = new FormData();
              console.log("formData:",formData);
              
              formData.append('image', file);
          
              // Ensure the correct key is passed
              dispatch(uploadTopicImage({ topic_id: selectedTopic?.id,formData: formData }))
                .then((res) => {
                  console.log("Image uploaded successfully:",res);
                  fetchTopics();
                })
                .catch((error) => console.error('Error uploading image:', error));
            } else {
              console.warn("No file provided for upload");
              fetchTopics();
            }
            resetFields();
            setPopup(false);
          })
          .catch((error) => console.error('Error updating topic:', error));
      } else {
        dispatch(addTopics(topicData))
          .then((response) => {
            console.log("Response:",response);
            
            const newTopicId = response?.payload?.data?.data?.data?.id;
            console.log("newTopicId:",newTopicId);
            
            if (file) {
              const formData = new FormData();
              console.log("formData:",formData);
              
              formData.append('image', file);
          
              // Ensure the correct key is passed
              dispatch(uploadTopicImage({ topic_id: newTopicId,formData: formData }))
                .then((res) => {
                  console.log("Image uploaded successfully:",res);
                  fetchTopics();
                })
                .catch((error) => console.error('Error uploading image:', error));
            } else {
              console.warn("No file provided for upload");
              fetchTopics();
            }
            resetFields();
            setPopup(false);
          })
          .catch((error) => console.error('Error creating topic:', error));
      }
    } else {
      alert('Please fill in the topic name!');
    }
  };

  const handleUpdateTopic = (topic) => {
    setIsEditMode(true);
    setSelectedTopic(topic);
    setEnTopicName(topic?.multilingual_names?.en || '');
    setTaTopicName(topic?.multilingual_names?.ta || '');
    setEnTopicDesc(topic?.multilingual_descriptions?.en || '');
    setTaTopicDesc(topic?.multilingual_descriptions?.ta || '');
    setFile(null);
    setPopup(true);
  };

  const handleTopicDelete = (deletedTopicId) => {
    setTopics((prevTopics) => prevTopics.filter((topic) => topic.id !== deletedTopicId));
  };

    const handleCreateNew = () => {
      setIsEditMode(false);  // Ensure it's in Create mode
      setSelectedTopic(null);  // No selected topic, to clear form
      setEnTopicName('');  // Clear the EN Topic Name
      setTaTopicName('');  // Clear the TA Topic Name
      setEnTopicDesc('');  // Clear the EN Topic Description
      setTaTopicDesc('');  // Clear the TA Topic Description
      setFile(null);  // Clear the file
      setPopup(true);  // Open the dialog
    };
    

  // const handleUpdateTopic = (topic) => {
  //   setIsEditMode(true);
  //   setSelectedTopic(topic);
  //   setEnTopicName(topic?.multilingual_names?.en || '');
  //   setTaTopicName(topic?.multilingual_names?.ta || '');
  //   setEnTopicDesc(topic?.multilingual_descriptions?.en || '');
  //   setTaTopicDesc(topic?.multilingual_descriptions?.ta || '');
  //   setFile(null);
  //   setPopup(true);
  // };

  // const handleTopicDelete = (deletedTopicId) => {
  //   setTopics((prevTopics) => prevTopics.filter((topic) => topic.id !== deletedTopicId));
  // };
  


  const handleFileChange = (e) => {
    const fileInput = e.target;
    if (fileInput && fileInput.files && fileInput.files[0]) {
      const selectedFile = fileInput.files[0];
      setFile(selectedFile);
      console.log("File selected:", selectedFile); // Log the selected file
    } else {
      console.error("No file selected or file input is undefined.");
    }
  }; 
   // Filter out any null or invalid data
   const filteredOptions = Array.isArray(topData)
   ? topData.filter((topic) => topic && topic.name)
   : [];

   //SelectNews 
   useEffect(()=>{
    if (newsdata.is_international_news) {
      setNewsName(NewsNameData.find((news) => news.name === "International News"));
    } else if (newsdata.is_national_news) {
      setNewsName(NewsNameData.find((news) => news.name === "National News"));
    } else if (newsdata.is_state_news) {
      setNewsName(NewsNameData.find((news) => news.name === "State News"));
    } else if (newsdata.is_local_news) {
      setNewsName(NewsNameData.find((news) => news.name === "Local News"));
    }
   },[newsdata])

  //  console.log("NewsName:",newsName);
   

  // NewsCharacterCount
  const maxCharacters = 250;

  //tacharacterCount
  const taHeadlinesCount = newsdata?.content?.ta?.headlines?.length || 0;
  const taMetaTitleCount = taMetaTitle?.length || 0;
  const taMetaDescriptionCount = tametadesc?.length || 0;
  const taTitleCount = newsdata?.content?.ta?.title?.length || 0;

  //enCharcterCount
  const enHeadlinesCount = newsdata?.content?.en?.headlines?.length || 0;
  const enMetaTitleCount = enMetaTitle?.length || 0;
  const enMetaDescriptionCount = enmetadesc?.length || 0;
  const enTitleCount = newsdata?.content?.en?.title?.length || 0;

    // Calculate word count
    const wordCountTa = content1 && typeof content1 === 'string' && content1.trim()
    ? content1.trim().split(/\s+/).length
    : 0;

const wordCountEn = content2 && typeof content2 === 'string' && content2.trim()
    ? content2.trim().split(/\s+/).length
    : 0;

    
  
    console.log(file3);


  const  handleSubmit = (e) => {
    e.preventDefault();
      const formData = {
        category_id: categoryName?.id, 
        city_id: cityname?.id,
        content:{
          ta:{
            headlines: newsdata.content.ta.headlines,
            title: newsdata.content.ta.title,
            web_content: content1,
            news_ad_id : tanewsad?.id || null,
            meta_description:newsdata?.content?.ta?.meta_description,
            meta_title:newsdata?.content?.ta?.meta_title,
            tags:taHashtags
          },
          en:{
            headlines: newsdata.content.en.headlines,
            title: newsdata.content.en.title,
            web_content: content2,
            news_ad_id : ennewsad?.id || null,
            meta_description:newsdata?.content?.en?.meta_description,
            meta_title:newsdata?.content?.en?.meta_title,
            tags:enHashtags
          }
        },
        is_breaking: newsdata.is_breaking,
        is_international_news: newsdata.is_international_news,
        is_national_news: newsdata.is_national_news,
        is_state_news: newsdata.is_state_news,
        is_local_news:newsdata.is_local_news,
        // meta_description: metadesc,
        // meta_title: metatitle,
        news_source:{
          name: newsdata?.news_source?.name ,
          url: newsdata?.news_source?.url 
        },
        news_type: newsdata?.news_type,
        state_id: statename?.id,
        // state_name:state_name?.name,
        // tags: hashtags,
        topics: [
          enTopicName?.id
        ]
      };
    const news_id = newsid
    // console.log(formData)
      setLoader(true);
      if (file3) {
          const formVata = new FormData();
          formVata.append('image', file3);
          dispatch(uploadimage({ news_id, formVata }));
        }
        dispatch(updatenews({news_id, formData}))
      .then(() => {
        setLoader(false);
        // Set the redirect flag to true after successful form submission and image upload
        // setShouldRedirect(true);
        // console.log("formData:",formData);
        
        if (formData.news_type === "short") {
          // console.log('Navigating to /news/short');
          navigate('/news?tab=short'); 
        } else if (formData.news_type === "web") {
          // console.log('Navigating to /news/web');
          navigate('/news?tab=web'); 
        }
      })
      .catch((err) => {
        //console.log(err);
        setLoader(false);
      });
      //   .then(() => {
      //   setLoader(false);
      //     //console.log(response?.payload?.data?.data?.data?.id);
      //     if (file3) {
      //       const formVata = new FormData();
      //       formVata.append('image', file3);
      //       dispatch(uploadimage({ news_id, formVata }))
      //       .then(() => {
      //         setLoader(false);
      //          if (formData.news_type === 0) {
      //           //console.log('Navigating to /news/short');
      //           navigate("/news/short");
      //       } else if (formData.news_type === 1) {
      //           //console.log('Navigating to /news/web');
      //           navigate("/news/web");
      //       }
      //       })
      //     }
      //     navigate("/cards");
          
      // })
      //   .catch((err) => {
      //   //console.log(err)
      //   setLoader(false);
      // })
  };

  const handleAction=(news_id, val,news_type)=>{
    setLoader(true);
    console.log("news_type:",news_type);
    
    dispatch(updateaction({news_id, val,news_type}))
    .then(() => {
      setLoader(false);
      switch (val) {
        case "revoke":
          dispatch(setStatus("pending"));
          toast.success("Action Revoked successfully");
          break;
        case "reject":
          dispatch(setStatus("pending"));
          toast.error("Action Rejected successfully");
          break;
        case "approve":
          dispatch(setStatus("pending"));
          toast.success("Action Approved successfully");
          break;
          default:
            console.error("Unhandled case:", val); 
      }
      if (newsdata.news_type === "short") {
        // console.log('Navigating to /news/short');
        navigate('/news?tab=short'); 
      } else if (newsdata.news_type === "web") {
        // console.log('Navigating to /news/web');
        navigate('/news?tab=web'); 
      }
      setLoader(false);
      setOpen(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  }

  const handleApprove=(news_id)=>{
      setLoader(true);
      const action = "approve";
      dispatch(updateaction({news_id, action}))
      .then(() => {
        setLoader(false);
        setOpen(false);
        navigate("/news");
      })
      .catch(()=>{
        setLoader(false);
      })
  }

  const handleReject=(news_id)=>{
    setLoader(true);
    const action = "reject";
    dispatch(updateaction({news_id, action}))
    .then(() => {
      setLoader(false);
      setOpen(false);
      navigate("/news");
    })
    .catch(()=>{
      setLoader(false);
    })
  }

const handlePublish=(news_id)=>{
  setLoader(true);
  dispatch(updatepublish({news_id}))
  .then(() => {
    setLoader(false);
    setOpen(false);
    navigate("/news");
  })
  .catch(()=>{
    setLoader(false);
  })
}

  const handleDelete = (news_id) =>{
    if(news_id){
      setLoader(true)
      dispatch(deletenews({news_id}))
      .then(() => {
        setLoader(false);
        setOpen(false);
        navigate("/news");
      })
      .catch(()=>{
        setLoader(false);
      })
    }
  }   
  const onChangeNewsName = (newsName) => {
    setNewsName(newsName);
    const updatedNewsData = NewsNameData.reduce((acc, item) => {
      acc[item.value] = item.value === newsName.value;
      return acc;
    }, {});
    console.log(updatedNewsData)
    setNewsdata((prev) => ({ ...prev, ...updatedNewsData }));
  }


  return (
    <Container maxWidth="fluid" className="content">
        <Box
           component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
        >
            <Grid container spacing={2} sx={{paddingBottom: 2}}>
            <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5"  sx={{fontWeight:'bold'}}>Edit News</Typography>
          </Grid>
          <Grid item xs={4} sx={{display:'flex', gap: 3, alignItems:'center'}}>
          {newsdata?.actions?.map((cd, index)=>{
        return(
          <Button key={index} onClick={()=> handleAction(newsdata.id, cd?.action,newsdata?.news_type)} variant='contained' color={cd?.negative_action ? 'primary':'success'}>{cd?.label}</Button>
        )
      })}
          {/* {(!newsdata?.is_published && (newsdata?.actions?.filter(ab => ab.action === "approve"))?.length !== 0) && <Button onClick={()=> handleApprove(newsdata.id)} color='secondary' size='large' sx={{marginTop: 3}} variant='contained'> </Button>}
          {(!newsdata?.is_published && (newsdata?.actions?.filter(ab => ab.action === "reject"))?.length !== 0) && <Button onClick={()=> handleReject(newsdata.id)} color='warning' size='large' sx={{marginTop: 3}} variant='contained'>Reject</Button>} */}
          {/* {!newsdata?.is_published ?  <Button onClick={()=> handlePublish(newsdata.id)} color='success' size='large' sx={{marginTop: 3}} variant='contained'>Publish</Button>
          :
          <Button color='success' size='large' sx={{marginTop: 3}} variant='contained'>Un Publish</Button>
          } */}
      <Button onClick={handleOpen} variant='outlined'>Delete</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete that news?
          </Typography>
          <Box sx={{display:'flex', justifyContent:'end', mt: 2}}>
          <Button variant='outlined' size="small" sx={{mr: 2}} onClick={handleClose}>No</Button>
          <Button variant='contained' size="small" onClick={() => handleDelete(newsdata.id)}>Yes</Button>
          </Box>
        </Box>
      </Modal>
                </Grid>
                {/* Update btn */}
                <Grid item xs={8} sx={{display:'flex',justifyContent:'end',alignItems:'center',gap:'15px' }}>
                <Button type='submit' sx={{marginTop: -1}} variant='contained'>Update</Button>
               {/* { */}
              {/* //  newstype !== "short" ?( */}
                {/* <Button type='submit' sx={{marginTop: 3}} variant='contained' color='info'>Preview</Button> */}
               {/* ):null
               } */}
                </Grid>
                <Grid item xs={6}>
                <Autocomplete
      options={catData}
      value={categoryName} 
      getOptionLabel={(option) => `${option.name}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select Category" variant="outlined" />}
      onChange={(event, newValue) => {
        setCategoryName(newValue);
      }}
    />
<Autocomplete
options={[...filteredOptions, { name: "Add Option" }]}
value={filteredOptions.find((option) => option.name === enTopicName?.name) || null}
 getOptionLabel={(option) => `${option?.multilingual_names?.en}` }
 // filterSelectedOptions={false}
 renderInput={(params) => (
   <TextField {...params} sx={{ marginBottom: 2 }} label="Select Topics" variant="outlined" />
 )}
  onChange={(event, newValue) => {
    console.log("Selected value:", newValue); // Debug the value
    if (newValue?.name === "Add Option") {
      handleCreateNew();
    } else {
      setEnTopicName(newValue); // Default to empty object if no value
    }
  }}
  renderOption={(props, option) =>
    option.name === "Add Option" ? (
      <li {...props} style={{ color: "blue" }}>
        + Create New
      </li>
    ) : (
      <li {...props}>{option?.multilingual_names?.en}</li> 
    )
  }
/>
           {/* Popup Dialog */}
           <Dialog open={popup} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle sx={{paddingTop:3}}>{isEditMode ? 'Edit Topic' : 'Add New Topic'}</DialogTitle>
        <DialogContent>
          <Box sx={{display:'flex',justifyContent:'space-between',columnGap:'20px'}}>
            <TextField fullWidth margin='normal' label='TA Topic Name' value={taTopicName || ''}
            onChange={(e)=> setTaTopicName(e.target.value)}
            />
            <TextField fullWidth margin='normal' label='EN Topic Name' value={enTopicName?.name} 
              onChange={(e) =>
                setEnTopicName((prev) => ({ ...prev, name: e.target.value })) // Update the 'name' property
              }
            />
            </Box>
            <Box sx={{display:'flex',justifyContent:'space-between',columnGap:'20px'}}>
            <TextField sx={{
              marginBottom: 1,
              '& .MuiInputBase-root': {height: '100px',display: 'flex', alignItems: 'start', overflow: 'hidden', position:'static',},
              '& .MuiInputBase-input': {overflow: 'auto', height: '100%'},
            }}fullWidth multiline  InputProps={{style: {overflow: "auto"}}}
             margin='normal' label='TA Topic Decription' value={taTopicDesc}
            onChange={(e)=> setTaTopicDesc(e.target.value)}/>

            <TextField sx={{
              marginBottom: 1,
              '& .MuiInputBase-root': {height: '100px',display: 'flex', alignItems: 'start', overflow: 'hidden', position:'static',},
              '& .MuiInputBase-input': {overflow: 'auto', height: '100%'},
            }}fullWidth multiline  InputProps={{style: {overflow: "auto"}}}
             margin='normal' label='EN Topic Decription' value={enTopicDesc}
            onChange={(e)=> setEnTopicDesc(e.target.value)}/>
            </Box>
             <Box sx={{marginBottom: 3, width:'60%',marginX: 'auto',}}>
                <Typography variant='body1' sx={{mb: 1}}>Image </Typography>
                {isEditMode && selectedTopic?.image?(
                <CloudUpload file={file} setFile={handleFileChange} setLoader={setLoader} imgUrl={isEditMode && selectedTopic?.image ? selectedTopic.image : null} />
                 ):( 
                 <CloudUpload file={file} setFile={setFile} setLoader={setLoader} imgUrl={null}/> 
                 )} 
            </Box>
        </DialogContent>
        <DialogActions sx={{marginBottom:3,marginRight:3}}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant='contained' onClick={handleAddNewTopic}>
            {isEditMode ? 'Update' : 'Create'}
            </Button>
        </DialogActions>
    </Dialog>

      {newstype ==="short" ?(
          <Autocomplete
          options={NewsNameData}
          value={newsName} 
          getOptionLabel={(option) => `${option.name}`}
          renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select News" variant="outlined" />}
                onChange={(event, newValue) => {
                  onChangeNewsName(newValue)
                  console.log(newsName)
            if (newValue?.name === "International News" || newValue?.name === "National News"){
              setCityName(null);
              setStateName(null);
            }else if (newValue?.name === "State News"){
              setCityName(null);
            }
            else if (newValue?.name === "Local News"){
              setStateName(null);
            }
          }}
          />
    ):null}

                        <Autocomplete
      options={cityData}
      value={cityname} 
      getOptionLabel={(option) => `${option.name}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select City" variant="outlined" />}
              onChange={(event, newValue) => {
                setCityName(newValue);
              }}
              disabled={isCityDisabaled}
    />
                        <Autocomplete
      options={stateData}
      value={statename} 
      getOptionLabel={(option) => `${option.name}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select State" variant="outlined" />}
      onChange={(event, newValue) => {
        // console.log(newValue)
        setStateName(newValue);
      }}
      disabled={isStateDisabled}
    />
                </Grid>
                <Grid item xs={3}sx={{marginTop:-1.4}}>
                {/* <div>
      <TextField
        label="Tags"
        variant="outlined"
        fullWidth
        value={inputValue}
        InputLabelProps={{ required: true }}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        placeholder="Press Enter to add tags"
      />
      <div style={{marginTop: 10}}>
        {hashtags.map((tag, index) => (
          <Chip
            key={index}
            label={`#${tag}`}
            onDelete={() => handleDeleteChip(index)}
            sx={{marginRight: 1}}
          />
        ))}
      </div>
    </div> */}
    {newstype !== "short" ?
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={newsdata?.is_breaking}
            onChange={handleCheckboxChange}
            name="is_breaking"
          />
        }
        label="Breaking News"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={newsdata.is_international_news}
            onChange={handleCheckboxChange}
            name="is_international_news"
          />
        }
        label="International News"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={newsdata.is_national_news}
            onChange={handleCheckboxChange}
            name="is_national_news"
          />
        }
        label="National News"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={newsdata.is_state_news}
            onChange={handleCheckboxChange}
            name="is_state_news"
          />
        }
        label="State News"
      />
    </FormGroup>
    : null
    }
                </Grid>
                <Grid item xs={3}>
                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="News Source Name" variant="outlined" InputLabelProps={{ required: true }}
                value={newsdata?.news_source?.name|| ''}
                onChange={(e)=>handleValue(e, 'nsname')}
                />
              <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="News Source URL" variant="outlined" InputLabelProps={{ required: true }}
                value={newsdata?.news_source?.url|| ''}
                onChange={(e)=>handleValue(e, 'nsurl')}
                />
                </Grid>
                <Grid item xs={6}>
                {newstype !== "short" ? 
                <div>
                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="TA Meta Title" variant="outlined" InputLabelProps={{ required: true }}
                value={taMetaTitle}
                onFocus={() => setIsFocussed('tametatitle')}
                onBlur={() => setIsFocussed(null)}
                onChange={(e)=>handleValue(e, 'tametatitle')}
                />
              {isFocussed ==='tametatitle' && taMetaTitleCount === 0 && (
                  <Typography variant="body2" color= 'error' sx={{ marginBottom: 1,marginLeft:1 }}>
                 Maximum {maxCharacters} characters
               </Typography>
              )}
               {isFocussed ==='tametatitle' && taMetaTitleCount > 0 && (
                <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1,marginLeft:1 }}>
                   {taMetaTitleCount} characters
                 </Typography>
              )}
                </div>
                : null
                }
                { newstype !== "short" ? 
                <div>
                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="TA Meta Description" variant="outlined" InputLabelProps={{ required: true }}
                 value={tametadesc}
                 onFocus={() => setIsFocussed('tametadescription')}
                 onBlur={() => setIsFocussed(null)}
                onChange={(e)=>handleValue(e, 'tametadescription')} 
                />
              {isFocussed === 'tametadescription' && taMetaDescriptionCount === 0 && (
                <Typography variant="body2" color= 'error' sx={{ marginBottom: 1,marginLeft:1 }}>
                 Maximum {maxCharacters} characters
               </Typography>
              )}
              {isFocussed === 'tametadescription' && taMetaDescriptionCount > 0 && (
                <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1,marginLeft:1 }}>
                   {taMetaDescriptionCount} characters
                 </Typography>
              )}
                </div>
                : null
                }
                { newstype !== "web" ? 
                                <Autocomplete
      options={adData}
      value={tanewsad} 
      getOptionLabel={(option) => `${option.title}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select TA News Ad" variant="outlined" />}
      onChange={(event, newValue) => {
        settaNewsAd(newValue);
      }}
    />
    : null}

{newsdata?.news_type !== "short" ? 
                <div>
      <TextField
        label="TA Tags"
        variant="outlined"
        fullWidth
        value={inputValueTa}
        InputLabelProps={{ required: true }}
        onChange={handleInputChangeTa}
        onKeyDown={handleInputKeyDownTa}
        placeholder="Press Enter to add tags"
      />
      <div style={{marginTop: 10,marginBottom:10}}>
        {(taHashtags || []).map((tag, index) => (
          <Chip
            key={index}
            label={`#${tag}`}
            onDelete={() => handleDeleteChipTa(index)}
            sx={{marginRight: 1}}
          />
        ))}
      </div>
    </div>
    : null}

                {/* only shortnews */}
              
                {
                  newstype === "short"?(
                    <>
                    {/* <TextField sx={{ marginBottom: 2 }} fullWidth id="outlined-basic" label="TA Title" variant="outlined" InputLabelProps={{ required: true }}
                    value={newsdata?.content?.ta?.title}
                    onChange={(e) => handleValue(e, 'tntitle')} /> */}
                    <div>
                    <TextField
                      sx={{
                        marginBottom: 1,
                        '& .MuiInputBase-root': {height: '100px',display: 'flex', alignItems: 'start', overflow: 'hidden', position:'static',},
                        '& .MuiInputBase-input': {overflow: 'auto', height: '100%'},
                      }}
                      fullWidth id="outlined-basic" label="TA News" variant="outlined"
                      InputLabelProps={{ required: true}}
                      InputProps={{
                        style: {overflow: "auto"}
                      }}
                      multiline
                      value={newsdata?.content?.ta?.headlines}
                      onFocus={() => setIsFocussed('tnheadlines')}
                      onBlur={() => setIsFocussed(null)}
                      onChange={(e) => handleValue(e, 'tnheadlines')}
                    />
                    {isFocussed === 'tnheadlines' && taHeadlinesCount === 0 && (
                   <Typography variant="body2" color= 'error' sx={{ marginBottom: 1,marginLeft:1 }}>
                    Maximum {maxCharacters} characters
                   </Typography>
                   )}
                {isFocussed === 'tnheadlines' && taHeadlinesCount > 0 && (
               <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1,marginLeft:1 }}>
                {taHeadlinesCount} characters
                </Typography>
               )}
              </div>       
                      <Box sx={{ height: '65%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px solid #ccc', borderRadius: '8px', overflow: 'hidden', padding: '16px' }}>
                      <img
                        src={file1}
                        alt="title Image"
                        style={{ maxWidth: '90%', maxHeight: '90%', objectFit: 'cover', borderRadius: '4px' }} />
                        {/* <iframe  width="100%" height="100%" scrolling="no"
                      srcdoc={cardPrev}
                      style={{ maxWidth: '90%', maxHeight: '90%', objectFit: 'cover', aspectRatio: '1/1',border: 'none',overflow: 'hidden', borderRadius: '4px'}}/> */}
                    </Box></>
                  ):(
                    <div>
                      <TextField sx={{ marginBottom: 1,marginTop:1.7}} fullWidth id="outlined-basic" label="TA Title" variant="outlined" InputLabelProps={{ required: true }}
                    value={newsdata?.content?.ta?.title}
                    onFocus={() => setIsFocussed('tntitle')}
                    onBlur={() => setIsFocussed(null)}
                    onChange={(e) => handleValue(e, 'tntitle')} />
                  {isFocussed ==='tntitle' && taTitleCount === 0 && (
                  <Typography variant="body2" color= 'error' sx={{ marginBottom: 1,marginLeft:1 }}>
                 Maximum {maxCharacters} characters
                 </Typography>
                 )}
                {isFocussed ==='tntitle' && taTitleCount > 0 && (
                <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1,marginLeft:1 }}>
                   {taTitleCount} characters
                 </Typography>
                )}
                </div>
                )
                }
                {/* <TextField sx={{marginBottom: 2}} multiline rows={2} fullWidth id="outlined-basic" label="Short Summary" variant="outlined" InputLabelProps={{ required: true }}
                  value={newsdata?.content?.ta?.short_summary}
                onChange={(e)=>handleValue(e, 'tnshort_summary')}
                />
                <Box sx={{marginBottom: 3}}>
                <Typography variant='body1' sx={{mb: 1}}>Summary Image *</Typography>
                <CloudUpload file={file1} setFile={setFile1} setLoader={setLoader}/>
                </Box> */}


                { newstype !== "short" ? 
                <>
                <Box sx={{display:'flex',justifyContent:'space-between',marginRight:2}}>
                  <div>
                <Typography variant='body1' sx={{mb: 1}}>TA News * 
                 <IconButton sx={{ fontSize: '0.9rem', color: 'primary.main' }}>
                  TA<ArrowForwardIcon sx={{ fontSize: '0.875rem', marginX: 0.5}} />EN
                 </IconButton>
                </Typography>
                </div>
                <Typography variant='body1'  color="textSecondary" sx={{mt:0.5}}> Word Count: {wordCountTa}</Typography>
                </Box>
                <Box sx={{height:'50%'}}>
                <Editor content={content1} setContent={setContent1} placeholder={'Write something...'} />
                </Box>
                </>
                : null
                }
                </Grid>
                <Grid item xs={6}>
               

    { newstype !== "web" ? 
                <Autocomplete
      options={adData}
      // style={{marginTop:'0px'}}
      value={ennewsad} 
      getOptionLabel={(option) => `${option.title}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select EN News Ad" variant="outlined" />}
      onChange={(event, newValue) => {
        setenNewsAd(newValue);
      }}
    />
              : null}
           <div style={{ position: 'relative' }}>
                {/* webnews uploads */}
              <div style={{ marginBottom: '28px', width: '100%', position: 'absolute', top: newstype === "short" ? '-225px' : '-150px' }}>
                {newsdata && newsdata?.images?.low_res_url ? (
                  <>
                    <CloudUpload setFile={setFile3} file={file3} setLoader={setLoader} imgUrl={newsdata?.images?.low_res_url} />
                  </>
                 ) : (
                   <CloudUpload file={file3} setFile={setFile3} setLoader={setLoader} />
                 )}
               </div>
             

            </div>

            {newstype !== "short" ?
            <div>
                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="EN Meta Title" variant="outlined" InputLabelProps={{ required: true }}
                value={enMetaTitle}
                onFocus={() => setIsFocussed('enmetatitle')}
                onBlur={() => setIsFocussed(null)}
                onChange={(e)=>handleValue(e, 'enmetatitle')}
                />
                {isFocussed ==='enmetatitle' && enMetaTitleCount === 0 && (
                  <Typography variant="body2" color= 'error' sx={{ marginBottom: 1,marginLeft:1 }}>
                 Maximum {maxCharacters} characters
                 </Typography>
                )}
              {isFocussed ==='enmetatitle' && enMetaTitleCount > 0 && (
                <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1,marginLeft:1 }}>
                   {enMetaTitleCount} characters
                 </Typography>
              )}
                </div>
                : null
                }
                { newstype !== "short" ?
                <div> 
                <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="EN Meta Description" variant="outlined" InputLabelProps={{ required: true }}
                 value={enmetadesc}
                 onFocus={() => setIsFocussed('enmetadescription')}
                 onBlur={() => setIsFocussed(null)}
                onChange={(e)=>handleValue(e, 'enmetadescription')} 
                />
              {isFocussed ==='enmetadescription' && enMetaDescriptionCount === 0 && (
                  <Typography variant="body2" color= 'error' sx={{ marginBottom: 1,marginLeft:1 }}>
                 Maximum {maxCharacters} characters
               </Typography>
              )}
              {isFocussed ==='enmetadescription' && enMetaDescriptionCount > 0 && (
                <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1,marginLeft:1 }}>
                   {enMetaDescriptionCount} characters
                 </Typography>
              )}
                </div>
                : null
                }

{ newstype !== "short" ?(
    <div>
      <TextField
        label="EN Tags"
        variant="outlined"
        fullWidth
        value={inputValueEn}
        InputLabelProps={{ required: true }}
        onChange={handleInputChangeEn}
        onKeyDown={handleInputKeyDownEn}
        placeholder="Press Enter to add tags"
        />
                
        <div style={{marginTop: 10,marginBottom:10}}>
        {(enHashtags || []).map((tag, index) => (
          <Chip
            key={index}
            label={`#${tag}`}
            onDelete={() => handleDeleteChipEn(index)}
            sx={{marginRight: 1}}
          />
        ))}
      </div>
    </div>
              ): null}


              {/* only shortnews */}

              {
                  newstype === "short"?(
                    <>
                    {/* <TextField sx={{ marginBottom: 2 }} fullWidth id="outlined-basic" label="EN Title" variant="outlined" InputLabelProps={{ required: true }}
                  value={newsdata?.content?.en?.title}
                  onChange={(e) => handleValue(e, 'entitle')} /> */}
                  <div>
                  <TextField
                   sx={{
                     marginBottom: 1,
                     '& .MuiInputBase-root': {height: '100px',display: 'flex', alignItems: 'start', overflow: 'hidden', position:'static',},
                     '& .MuiInputBase-input': {overflow: 'auto', height: '100%'},
                   }}
                   fullWidth id="outlined-basic" label="EN News" variant="outlined"
                   InputLabelProps={{ required: true}}
                   InputProps={{
                     style: {overflow: "auto"}
                   }}
                   multiline
                    value={newsdata?.content?.en?.headlines}
                    onFocus={() => setIsFocussed('enheadlines')}
                    onBlur={() => setIsFocussed(null)}
                    onChange={(e) => handleValue(e, 'enheadlines')} />
                  {isFocussed === 'enheadlines' && enHeadlinesCount === 0 && (
                    <Typography variant="body2" color= 'error' sx={{ marginBottom: 1,marginLeft:1 }}>
                      Maximum {maxCharacters} characters
                    </Typography>
                  )}
                  {isFocussed === 'enheadlines' && enHeadlinesCount > 0 && (
                    <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1,marginLeft:1 }}>
                      {enHeadlinesCount} characters
                    </Typography>
                  )}
                    </div>

                    <Box sx={{ height: '65%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px solid #ccc', borderRadius: '8px', overflow: 'hidden', padding: '16px' }}>
                    <img
                      src={file2}
                      alt="title Image"
                      style={{ maxWidth: '90%', maxHeight: '90%', objectFit: 'cover', borderRadius: '4px' }} />
                    {/* <iframe  width="100%" height="100%" scrolling="no"
                      srcdoc={cardPrev}
                      style={{ maxWidth: '90%', maxHeight: '90%', objectFit: 'cover', aspectRatio: '1/1',border: 'none',overflow: 'hidden', borderRadius: '4px'}}/> */}
                  </Box>
                  </>
                  ):(
                    <div>
                      <TextField sx={{ marginBottom: 1,marginTop:1.7}} fullWidth id="outlined-basic" label="EN Title" variant="outlined" InputLabelProps={{ required: true }}
                    value={newsdata?.content?.en?.title}
                    onFocus={() => setIsFocussed('entitle')}
                    onBlur={() => setIsFocussed(null)}
                    onChange={(e) => handleValue(e, 'entitle')} />
                  {isFocussed ==='entitle' && enTitleCount === 0 && (
                  <Typography variant="body2" color= 'error' sx={{ marginBottom: 1,marginLeft:1 }}>
                 Maximum {maxCharacters} characters
               </Typography>
              )}
              {isFocussed ==='entitle' && enTitleCount > 0 && (
                <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1,marginLeft:1 }}>
                   {enTitleCount} characters
                 </Typography>
              )}
                    </div>
                  )
                }

               {/* EN Content */}
               {newstype !== "short" && (
                <>
                 {/* <div style={{ marginTop: '45px' }}>  */}
                 <Box sx={{display:'flex',justifyContent:'space-between',marginRight:2}}>
                <div>
                <Typography variant='body1' sx={{mb: 1}}>EN News * 
                 <IconButton sx={{ fontSize: '0.9rem', color: 'primary.main' }}>
                  EN<ArrowForwardIcon sx={{ fontSize: '0.875rem', marginX: 0.5}} />TA
                 </IconButton>
                </Typography>
                </div>
                <Typography variant='body1'  color="textSecondary" sx={{mt: 0.5}}> Word Count: {wordCountEn}</Typography>
                </Box>
                   <Box sx={{ height: '50%', position: 'static' }}>
                     <Editor content={content2} setContent={setContent2} placeholder={'Write something...'} />
                   </Box>
                 {/* </div> */}
                 </>
                )}
                 {/* <CardEndpoint shouldRedirect={shouldRedirect}/> */}
                
                
                {/* <TextField sx={{marginBottom: 2}} multiline rows={2} fullWidth id="outlined-basic" label="Short Summary" variant="outlined" InputLabelProps={{ required: true }}
                  value={newsdata?.content?.en?.short_summary}
                onChange={(e)=>handleValue(e, 'enshort_summary')}
                />
                <Box sx={{marginBottom: 3}}>
                <Typography variant='body1' sx={{mb: 1}}>Summary Image *</Typography>
                <CloudUpload file={file2} setFile={setFile2} setLoader={setLoader}/>
                </Box> */}
                {/* { newstype !== "short" ? 
                <>
                <Typography variant='body1' sx={{mb: 1}}>EN Content *</Typography>
                <Box sx={{height:'50%' ,position:'static'}}>
                <Editor content={content2} setContent={setContent2} placeholder={'Write something...'} />
                </Box>
                </>
                : null} */}
                </Grid>
            </Grid>
       </Box>
    </Container>
  );
}