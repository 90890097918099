import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { updateaction } from '../../slices/news';
import { useDispatch } from 'react-redux';

export default function MediaCard({val, setLoader, setNewsid}) {

  const [subshow, setSubshow] = React.useState(false);

  const dispatch = useDispatch();

  const date = new Date(val?.created_at);

// Get the formatted date
const formattedDate = date.toLocaleDateString('en-GB', {
  day: '2-digit',
  month: 'short',
  year: 'numeric'
});

// Get the formatted time
const formattedTime = date.toLocaleTimeString('en-GB', {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false
});

const handleOption = () =>{
  setSubshow(!subshow);
}

const styles = {
  textContainer: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};


const handleAction=(val)=>{
  setNewsid((prevItems) => [...prevItems, val]);
}

// const handleReject=(news_id)=>{
// setLoader(true);
// const action = "reject";
// dispatch(updateaction({news_id, action}))
// .then(() => {
//   setLoader(false);
//   window.location.reload();
// })
// .catch(()=>{
//   setLoader(false);
// })
// }

  return (
    <Grid item spacing={2} padding={3}>
    <Card sx={{ maxWidth: '100%', height: '450px', boxShadow:'none', border:'1px solid #cacaca', borderRadius: 3, p: 1 }}>
    <CardContent>
        <Box component="div" sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
        {/* <Typography variant="span" >
          Category: {val?.category?.name}
        </Typography> */}
        </Box>
        {/* <Typography style={styles.textContainer} variant="p" color="text.secondary" sx={{fontSize: 16, mt: 1}}>
        {val?.content?.en?.headlines}
        </Typography> */}
      </CardContent>
        <CardMedia
        sx={{ height: 250, mx: 2, borderRadius: 3 }}
        image={val?.images?.low_res_url}
        title="green iguana"
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {val?.title}
        </Typography>
      </CardContent>
      <CardActions>
          <Button onClick={()=> handleAction(val)} variant='outlined' color={'primary'} sx={{width:'100%'}}>Add News</Button>
      </CardActions>
    </Card>
    </Grid>
  );
}
