import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Modal, Typography, IconButton, Tabs, Tab, Slider } from '@mui/material';
import { Close } from '@mui/icons-material';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../components/button/getCroppedImage';


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '500px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const cropModalStyle = {
  ...modalStyle,
  width: '40%', // Adjust width for cropping modal
};


export default function CloudUpload({ setFile, file, setLoader, imgUrl }) {
  const [imageData, setImageData] = useState();
  const [open, setOpen] = useState(false);
  const [cropOpen, setCropOpen] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setShowCropper(false);
  };

  const convertImgUrlToFile = async (imgUrl, fileName = 'image.jpg') => {
    try {
      const response = await fetch(imgUrl);
      const blob = await response.blob(); // Convert to Blob
      const file = new File([blob], fileName, { type: blob.type }); // Create a File object
      setFile(file); // Set File object in useState
    } catch (error) {
      console.error('Error converting image URL to file:', error);
    }
  };
  const handleCropModalClose = () => {
    setCropOpen(false);
    setShowCropper(false);
  };

  const handleCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleFileChange = async (event) => {
    const dataImg = event.target.files[0];
    // console.log(dataImg)
    setFile(dataImg);
    setImageData(null);
  
    if (dataImg) {
      const reader = new FileReader();
      
      reader.onload = () => {
        setImageSrc(reader.result); // Set the image source for cropping
        setCropOpen(true); 
      };
      
      reader.readAsDataURL(dataImg);
    }
  };
  
  // useEffect(() => {
  //   if (imgUrl) {
  //     convertImgUrlToFile(imgUrl)
  //   }
  // }, [])
  
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFileChange({ target: { files } });
    }
  };

  const handleUpload = async () => {
    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
    console.log(croppedImage)
    setFile(croppedImage);
    setOpen(false)
    setImageData(URL.createObjectURL(croppedImage));
    handleCropModalClose(); 
  };

  return (
    <>
      {/* Upload Button */}
      <Button
        sx={{ width: '100%', py: 2 }}
        size="large"
        component="label"
        variant="outlined"
        startIcon={!file || !imageData ? <CloudUploadIcon /> : null}
        onClick={() => {
          console.log('File:', file);          
          console.log('ImageData:', imageData); 
          console.log('ImageSrc:', imageSrc);   
          console.log('ImgUrl:', imgUrl);       
          console.log(
            'StartIcon Condition:',
            !file || !imageData ? 'CloudUploadIcon displayed' : 'No icon'
          ); 
          handleOpen();
        }}
      
      >
        {

          imageSrc ? <img id="imagePreview" width={100} src={imageSrc} alt="img" /> : <img id="imagePreview" width={100} src={imgUrl} alt="img" />
        }
         
      </Button>

      {/* Modal for Drag-and-Drop or Browse */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Upload Image</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <Tabs
            value={selectedTab}
            onChange={(event, newValue) => setSelectedTab(newValue)}
            sx={{ mt: 2, mb: 2, borderBottom: '1px solid #ccc' }}
          >
            <Tab label="Upload" />
            <Tab label="Search Images" />
            <Tab label="Newsit Library" />
          </Tabs>
          <Box
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            sx={{
              width: '75%',
              height: '200px',
              marginLeft: '80px',
              border: dragOver ? '2px dashed #1976d2' : '2px dashed #ccc',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              rowGap: '20px',
              padding: '20px',
              mt: 10,
            }}
          >
            {selectedTab === 0 && (
              <>
                <Button component="label" variant="contained" sx={{ mt: 1 }}>
                  Browse
                  <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                </Button>
                <Typography>or Drag and drop an image</Typography>
              </>
            )}

            {selectedTab === 1 && (
              <>
              <Button component="label" variant="contained" sx={{ mt: 1 }}  
              onClick={() => {
                window.open('http://localhost:8501', '_blank');
               }}>
                  Browse
                </Button>
              </>
            )}

            {selectedTab === 2 && (
              <Box sx={{ mt: 2 }}>
                <Typography>Local Images integration coming soon...</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>

      {/* Crop Modal */}
      <Modal open={cropOpen} onClose={handleCropModalClose}>
        <Box sx={cropModalStyle}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Crop Image</Typography>
            <IconButton onClick={handleCropModalClose}>
              <Close />
            </IconButton>
          </Box>
          <Box mt={4} position="relative" width="100%" height="300px">
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={16 / 9}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={handleCropComplete}
            />
            <Box mt={2} width="80%" height="300px" mx="auto">
              {/* <Typography gutterBottom>Zoom</Typography> */}
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                onChange={(e, value) => setZoom(value)}
              />
            </Box>
            <Button variant="contained" color="primary" onClick={handleUpload} sx={{ mt: 2 }}  >
              Upload
              
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
