import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { deletestories, updateaction } from '../../slices/news';
import { useDispatch } from 'react-redux';

export default function MediaCard({ val, setLoader }) {
  //console.log(val?.images?.original_url)

  const [subshow, setSubshow] = React.useState(false);

  const dispatch = useDispatch();

  const date = new Date(val?.created_at);
  // //console.log(val?.category?.name);

// Get the formatted date
const formattedDate = date.toLocaleDateString('en-GB', {
  day: '2-digit',
  month: 'short',
  year: 'numeric'
});

// Get the formatted time
const formattedTime = date.toLocaleTimeString('en-GB', {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false
});

const handleOption = () =>{
  setSubshow(!subshow);
}

const styles = {
  textContainer: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

const handleAction=(news_id, val)=>{
  //console.log(val);
  setLoader(true);
  dispatch(updateaction({news_id, val}))
  .then(() => {
    setLoader(false);
    window.location.reload();
  })
  .catch(()=>{
    setLoader(false);
  })  
}
const handleDeleteClick = (stories_id) => {
  setLoader(true);
  dispatch(deletestories({ stories_id }))
    .then(() => {
      setLoader(false);
    })
    .catch(() => {
      setLoader(false);
    });
};


// const handleDeleteClick = (stories_id) => {
//   DeletStories(stories_id);
//   window.location.reload();
// };

// React.useEffect(()=>{
//   DeletStories()
// },[DeletStories])

// const handleReject=(news_id)=>{
// setLoader(true);
// const action = "reject";
// dispatch(updateaction({news_id, action}))
// .then(() => {
//   setLoader(false);
//   window.location.reload();
// })
// .catch(()=>{
//   setLoader(false);
// })
// }

  return (
    <Grid item xs={3} md={3} lg={3}>
    <Card sx={{ maxWidth: '100%', height: '400px', boxShadow:'none', border:'1px solid #cacaca', borderRadius: 3, p: 1 }}>
    <CardContent>
        <Box component="div" sx={{display:'flex',flexDirection:'column',alignItems:'flex-end',}}>
        {/* <Typography variant="span" >
          Category: {val?.name}
        </Typography> */}
        <Button size='small' sx={{position:'relative', minWidth: 0, padding: 0}} onClick={handleOption}><MoreVertIcon/>
          { subshow && 
          <Box sx={{position:'absolute', bgcolor:'#fff', top: '30px', right: 0, boxShadow:'0px 0px 10px #cacaca', borderRadius: 2, p: 2}}>
          <Link to={`editstories/${val.id}`} style={{textDecoration:'none'}}>
                            <Box sx={{ py: 1, px: 1 }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography sx={{ color: "#000" }}>
                                  Edit
                                </Typography>
                                
                              </div>
                            </Box>
                            
                          </Link>
                  <Box sx={{ py: 1, px: 1 }} onClick={() => handleDeleteClick(val?.id)}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ color: "red" }}>
                          Delete
                        </Typography>

                      </div>
                    </Box>
                          {/* <Link style={{textDecoration:'none'}}>
                            <Box sx={{ py: 1, px: 1 }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography sx={{ color: "#000" }}>
                                Approve
                                </Typography>
                              </div>
                            </Box>
                          </Link>
                          <Link style={{textDecoration:'none'}}>
                            <Box sx={{ py: 1, px: 1 }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography sx={{ color: "#000" }}>
                                  Reject
                                </Typography>
                              </div>
                            </Box>
                          </Link> */}
          </Box>
          }
          </Button>
        </Box>
         {/* <Typography style={styles.textContainer} variant="p" color="text.secondary" sx={{fontSize: 16, mt: 1}}>
        {val?.content?.en?.headlines}
        </Typography>  */}
        </CardContent>
      <CardMedia
        sx={{ height: 250,width:300, mx: 2, borderRadius: 3 }}
        component="img"
        image={val?.images?.original_url}
          // src={"https://stg-newsit-media1.s3.amazonaws.com/ad_images/ta/Screenshot (2)_low_res.png"}
          // src={}
        title={val?.multilingual_names?.en}
      />
      
      <CardContent sx={{display:'flex',flexDirection:'column',rowGap:'10px'}}>
        <Typography variant="p" color="text.primary">
        {val?.multilingual_names?.en}
        </Typography>
        <Typography variant="p" color="text.primary">
          {val?.multilingual_names?.ta}
        </Typography>
      </CardContent>
      </Card>
    </Grid>
    // <img style={{ backgroundColor: "red" }} src={val?.images?.thumbnail_url} width={100} height={100} />
  );
}
