import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Button, Container } from '@mui/material';
import BasicTabs from '../../components/tabs/CommentsTab';
import { getnews, getnewsstories, upnews } from '../../slices/news';

const Comments = ({setLoader}) => {


  return (
    <Container className='content' maxWidth="fluid">
        <BasicTabs />
    </Container>
  )
}

export default Comments