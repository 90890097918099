// import React from 'react'

// const CardEndpoint = ({ shouldRedirect }) => {
//   const navigate = useNavigate();
// //   //console.log("hiii");
  

//   useEffect(() => {
//     if (shouldRedirect) {
//       navigate('/news?tab=='); // Only navigate when shouldRedirect is true
//     }
//   }, [shouldRedirect, navigate]);

//   return 
//   <></>;
// };

// export default CardEndpoint

import React from 'react'

const CardEndpoint = () => {
  return (
    <div>CardEndpoint</div>
  )
}

export default CardEndpoint