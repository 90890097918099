import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { TextField, Chip, FormControlLabel, Radio, Container, Modal, Autocomplete, IconButton, FormGroup, InputBase, Tabs } from '@mui/material';
import CloudUpload from '../../components/button/Cloudupload';
import { Grid } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Editor from './Editor';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createnews, createnewsstories, deletenews, getcategories, getcity, getnewsad, getnewsbyid, getstates, getstorynews, gettopics, updatenews, uploadstoryimage } from '../../slices/news';
import MediaCard from '../../components/card/NewsCard';
import MediaCard1 from '../../components/card/EditStoryCard';
import SearchIcon from '@mui/icons-material/Search';
import { Close } from '@mui/icons-material';
import UploadImageModel from '../../components/button/UploadImageModel';



const Search = styled('div')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: '1px solid #000',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 2),
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#000',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1.5, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '25ch',
    },
  },
}));

//VisuallyInput
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});






const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 2,
  borderRadius: 2
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



export default function Addstories({ setLoader, imgUrl }) {

  const [imageData, setImageData] = useState(imgUrl);
  const [selectedTab, setSelectedTab] = useState(0);

  const params = useParams();

  const [open, setOpen] = React.useState(0);
  const handleOpen = (id) => {
    //console.log(id);
    setOpen(id);
  }
  const handleClose = () => setOpen(0);


  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { categories: catData } = useSelector((state) => state.news);

  // const {storyNews: newsData} = useSelector((state) => state.news);

  const { topics: topData } = useSelector((state) => state.news);

  const { newsAd: adData } = useSelector((state) => state.news);

  const { cities: cityData } = useSelector((state) => state.news);

  const { states: stateData } = useSelector((state) => state.news);

  const [categoryName, setCategoryName] = React.useState(null);

  const [topicsname, setTopicsName] = React.useState(null);

  const [newsid, setNewsid] = useState([]);

  const [newsSourceName, setNewsSourceName] = useState("");

  const [tanewsad, settaNewsAd] = React.useState(null);

  const [ennewsad, setenNewsAd] = React.useState(null);

  const [cityname, setCityName] = React.useState(null);

  const [statename, setStateName] = React.useState(null);

  const [previewSrc, setPreviewSrc] = useState(null);

  const [file1, setFile1] = useState(null);

  const [file2, setFile2] = useState(null);

  const [inputValue, setInputValue] = useState('');
  const [hashtags, setHashtags] = useState([]);

  const [content1, setContent1] = useState('');

  const [content2, setContent2] = useState('');


  const [newsdata, setNewsdata] = useState({
    category_id: '',
    multilingual_descriptions: {
      "en": "News Topic Description",
      "ta": "Descripción del tema de noticias"
    },
    multilingual_names: {
      en: "",
      ta: ""
    },
    name: "",
    news_ids: []
  })
  const handleValue = (e, field) => {
    const { value } = e.target;
  
    // Handle updates based on the field name
    setNewsdata(prevData => {
      switch (field) {
        case 'name':
          return {
            ...prevData,
            name: value, // Update the name field directly
          };
  
        case 'multilingual_names_en':
          return {
            ...prevData,
            multilingual_names: {
              ...prevData.multilingual_names,
              en: value, // Update the English multilingual name
            },
          };
       
        case 'multilingual_names_ta':
          return {
            ...prevData,
            multilingual_names: {
              ...prevData.multilingual_names,
              ta: value, // Update the Tamil multilingual name
            },
          };
  
        default:
          return prevData; // Return the previous state if no matching field is found
      }
    });
  };
  
  
  


  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const value = inputValue.trim();
      if (value) {
        setHashtags([...hashtags, value]);
        setInputValue('');
      }
    }
  };

  const handleDeleteChip = (index) => {
    const updatedHashtags = [...hashtags];
    updatedHashtags.splice(index, 1);
    setHashtags(updatedHashtags);
  };

  const handleCheckboxChange = (event) => {
    setNewsdata({
      ...newsdata,
      [event.target.name]: event.target.checked,
    });
  };

  const arrowBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    setLoader(true)
    const formData = {
      "filter": {},
      "sort": "_id",
      "order": 1,
      "page": 0,
      "page_size": 100
    }
    const params = {
      "page": 1,
      "page_size": 100
    }
    // dispatch(getstorynews(params))
    dispatch(getcategories(formData))
    dispatch(gettopics(formData))
    dispatch(getnewsad(formData))
    dispatch(getcity())
    dispatch(getstates())
      // dispatch(getnewsbyid(params.newsid))
      .then((data) => {
        const upData = data.payload.newsdata.data
        setNewsdata(upData);
        setCategoryName(upData?.category);
        // setTopicsName(newsdata?.topics?.length > 0 && newsdata?.topics[0]?.multilingual_names?.en);
        setHashtags(upData.tags);
        settaNewsAd(upData?.content?.ta?.news_ad);
        setenNewsAd(upData?.content?.en?.news_ad)
        // setFile1(upData?.news_card_images?.en?.original_url);
        // setFile2(upData?.news_card_images?.ta?.original_url);
        setContent1(upData?.content?.ta?.web_content);
        setContent2(upData?.content?.en?.web_content);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      })
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Check if categoryName is selected
    if (!categoryName) {
      alert("Please select a category before submitting.");
      return;
    }
  
    const formData = newsdata;
    setLoader(true);
  
    try {
      // Create news story
      const response = await dispatch(createnewsstories(formData));
      console.log("Created response:", response);
      const news_id = response?.payload?.data?.data?.data?.id;
  
      if (!news_id) {
        alert("Failed to create news story.");
        setLoader(false);
        return;
      }  
      if (!file1) {
        alert("No file selected.");
        setLoader(false);
        return;
      }
      const formVata = new FormData();
      formVata.append('image', file1);
  

      await dispatch(uploadstoryimage({ news_id, formVata }));
  
      // After both actions are completed, stop the loader and navigate
      setLoader(false);
      navigate("/newsstories");
    } catch (error) {
      // Handle errors by stopping the loader
      setLoader(false);
      console.error("Error submitting form:", error);
    }
  };
  

  // Handle file input change
  const handleFileChange = (event, index) => {
    //console.log(index);
    const dataImg = event.target.files[0];
    if (index == 1) {
      setFile1(dataImg);
    }
    if (index == 2) {
      setFile2(dataImg);
    }
    setImageData(null);

    if (dataImg) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const imagePreview = document.getElementById(`imagePreview${index}`);
        imagePreview.src = e.target.result;
        imagePreview.style.display = 'block';
      };
      reader.readAsDataURL(dataImg);

      // Close modal when file is selected
      handleClose();
    }
  };

  const handelChangeCategory = (category) => {
    if (category) {
      setCategoryName(category);
      setNewsdata((prev) => ({ ...prev, category_id: category.id }));
    } else {
      setCategoryName(null);
      setNewsdata((prev) => ({ ...prev, category_id: '' }));  // Reset if no category selected
    }
  }
  
   

  return (
    <Container maxWidth="fluid" className="content">
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2} sx={{ paddingBottom: 2 }}>
          <Grid
            item
            xs={8}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Add Stories</Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: 'end' }}>
            <Button type='submit' size='large' sx={{ marginTop: 3 }} variant='contained'>Create Story</Button>
          </Grid>
          <Grid item xs={9} sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }} >
            <Autocomplete options={catData} value={categoryName} getOptionLabel={(option) => `${option.name}`} renderInput={(params) => <TextField {...params} sx={{ marginBottom: 2 }} label="Select Category" variant="outlined" />} onChange={(event, newValue) => handelChangeCategory(newValue)}
            />

            <TextField sx={{ marginBottom: 2, width: '100%' }} fullWidth id="outlined-basic" label="Topic" variant="outlined" InputLabelProps={{ required: true }}
              value={newsdata?.name|| ''}
              onChange={(e) => handleValue(e, 'name')}
            />
            <Grid sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
              <Grid className='wrapper' sx={{ width: '50%' }}>
                <Grid item xs={9}>
                  <TextField sx={{ marginBottom: 2, width: '100%' }} fullWidth id="outlined-basic" label="EN Name" variant="outlined" InputLabelProps={{ required: true }}
                    value={newsdata?.multilingual_names?.en}
                    onChange={(e) => handleValue(e, 'multilingual_names_en')}
                  />
                </Grid>
                {/* Box Divider */}
                <Box sx={{ height: '300px', width: '55%', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px solid #ccc', borderRadius: '8px', overflow: 'hidden', padding: '16px' }}>
                  {/* Upload Button */}
                  <Button
                    sx={{ width: '100%', py: 2 }}
                    size="large"
                    component="label"
                    variant="outlined"
                    startIcon={!file1 || !imgUrl ? <CloudUploadIcon /> : null}
                    onClick={() => handleOpen(1)}
                  >
                    {file1 ? (
                      <img id="imagePreview1" width={100} src={imgUrl} alt="img" />
                    ) : imgUrl ? (
                      <img width={100} src={imgUrl} alt="img" />
                    ) : (
                      'Upload Image'
                    )}

                  </Button>
                  {open == 1 && <UploadImageModel handleFileChange={handleFileChange} open={1} handleClose={handleClose} index={1} />}

                </Box>
              </Grid>
              <Grid sx={{ width: '50%' }}>
                <Grid item xs={9}>
                <TextField sx={{ marginBottom: 2, width: '100%' }} fullWidth id="outlined-basic" label="TA Name" variant="outlined" InputLabelProps={{ required: true }}
                    value={newsdata?.multilingual_names?.ta}
                    onChange={(e) => handleValue(e, 'multilingual_names_ta')}
                  />
                </Grid>
                {/* <Box sx={{ height: '300px', width: '55%', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px solid #ccc', borderRadius: '8px', overflow: 'hidden', padding: '16px' }}>
                           <Button
                             sx={{ width: '100%', py: 2 }}
                             size="large"
                             component="label"
                             variant="outlined"
                             startIcon={!file2 || !imgUrl ? <CloudUploadIcon /> : null}
                             onClick={() => handleOpen(2)}
                           >
                             {file2 ? (
                               <img id="imagePreview2" width={100} src={imgUrl} alt="img" />
                             ) : imgUrl ? (
                               <img width={100} src={imgUrl} alt="img" />
                             ) : (
                               'Upload Image')}
                  </Button>
                  {open == 2 && <UploadImageModel handleFileChange={handleFileChange} open={2} handleClose={handleClose} index={2} />} 
                </Box>  */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            {newsid?.map((val, index) => (
              <MediaCard setLoader={setLoader} key={index} val={val} />
            ))}
          </Grid>
          {/* <Grid item xs={4} sx={{marginTop:'-25.5%'}}>
                <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
                {newsData?.map((val, index) => (
                  <MediaCard setNewsid={setNewsid} setLoader={setLoader} key={index} val={val}/>
                ))}
                </Grid> */}
        </Grid>
      </Box>
    </Container>
  );
}