// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar{
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1111;
    height: 80px;
    background-color: #fff;
    box-shadow: 0px 7px 10px #d4d4d4;
    border-radius: 200px;
}
.sidemenu:hover{
    border-radius: 50px;
    background-color: #dfd8ff;
}
.sidemenu{
    margin: 10px;
}
.sidemenu.active svg{
    color: #c00404;
}
.sidemenu.active p{
    color: #c00404;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/layouts/sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,SAAS;IACT,2BAA2B;IAC3B,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,gCAAgC;IAChC,oBAAoB;AACxB;AACA;IACI,mBAAmB;IACnB,yBAAyB;AAC7B;AACA;IACI,YAAY;AAChB;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".sidebar{\n    position: fixed;\n    bottom: 30px;\n    left: 50%;\n    transform: translateX(-50%);\n    z-index: 1111;\n    height: 80px;\n    background-color: #fff;\n    box-shadow: 0px 7px 10px #d4d4d4;\n    border-radius: 200px;\n}\n.sidemenu:hover{\n    border-radius: 50px;\n    background-color: #dfd8ff;\n}\n.sidemenu{\n    margin: 10px;\n}\n.sidemenu.active svg{\n    color: #c00404;\n}\n.sidemenu.active p{\n    color: #c00404;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
