import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Button, Container } from '@mui/material';
import BasicTabs from '../../components/tabs/LibraryTab';
import { getnews, getnewsstories, upnews } from '../../slices/news';

const Library = ({setLoader}) => {


  return (
    <Container className='content' maxWidth="fluid">
        <BasicTabs />
    </Container>
  )
}

export default Library