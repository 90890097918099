
import { createSlice } from "@reduxjs/toolkit";

const statusSlice = createSlice({
    name: "status",
    initialState: "pending",
    reducers: {
        setStatus: (state, action) => action.payload,
    },
});

export const { setStatus } = statusSlice.actions;
export default statusSlice.reducer;
