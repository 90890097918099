import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import { Box, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { Home } from "@mui/icons-material";
import classNames from "classnames";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import LocalPoliceOutlinedIcon from "@mui/icons-material/LocalPoliceOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import logo from "../../assets/images/white_logo.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MessageIcon from '@mui/icons-material/Message';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import ScheduleIcon from '@mui/icons-material/Schedule';
import FeedIcon from '@mui/icons-material/Feed';
import MovieIcon from '@mui/icons-material/Movie';
import SettingsIcon from '@mui/icons-material/Settings';

const Sidebar = ({ show, setShow }) => {
  const [selectmenu, setSelectmenu] = useState("");
  const [subnav, setSubnav] = useState('');
  const location = useLocation();

  const side_menus = [
    {
      menuName: "Home",
      icon: HomeOutlinedIcon,
      route: "/",
    },
    {
      menuName: "News",
      icon: FeedIcon,
      route: "/news",
    },
    {
      menuName: "News Stories",
      icon: ScheduleIcon,
      route: "/newsstories",
    },
    {
      menuName: "Comments",
      icon: MessageIcon,
      route: "/comments",
    },
    {
      menuName: "Library",
      icon: PhotoLibraryIcon,
      route: "/library",
    },
    {
      menuName: "Watch",
      icon:  MovieIcon ,
      route: "/watch",
    },
    {
      menuName: "Settings",
      icon: SettingsIcon,
      route: "/settings",
    },
  ];

  //console.log(subnav)

  const handleMenu = (val) => {
    if(val.subNav && subnav !== val.menuName){
      setSubnav(val.menuName);
    }
    else{
      setSelectmenu(val.menuName);
      setSubnav("");
    }
  };

  const handleSubMenu = (val) => {
      setSelectmenu(val);
  };

  useEffect(() => {
    const val = window.location.pathname;
    for (let i = 0; i < side_menus.length; i++) {
      if(side_menus[i].subNav){
        for (let j = 0; j < side_menus[i].subNav.length; j++) {
          if (val === side_menus[i].subNav[j].route) {
            setSubnav(side_menus[i].menuName);
            setSelectmenu(side_menus[i].subNav[j].menuName);
          }  
        }
      }
      else if (val === side_menus[i].route) {
        setSelectmenu(side_menus[i].menuName);
      }
    }
  }, []);

  return (
    <div className="sidebar">
      <Box>
        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center',}}>
          {side_menus.map((val, index) => {
            return (
              <div key={index}>
                <Link
                  
                  to={val.route}
                  onClick={() => handleMenu(val)}
                  style={{ textDecoration: "none" }}
                >
                  <Box
                   className={classNames(
                    "sidemenu",
                    { "active": location.pathname === val.route }
                  )}
                    sx={{ py: 1, px: 2 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent:'space-between',
                        flexDirection:'column'
                      }}
                    >
                      <div
                       style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent:'space-between',
                        flexDirection:'column'
                       }}
                      >
                      <val.icon sx={{ color: "#000", marginBottom: 0.5 }} />
                      <Typography sx={{ color: "#000", fontSize:'12px' }}>
                        {val.menuName}
                      </Typography>
                      </div>
                      {val.subNav &&
                        (subnav === val.menuName ? (
                          <KeyboardArrowUpIcon sx={{ color: "#fff", textAlign:'end', }} />
                        ) : (
                          <KeyboardArrowDownIcon sx={{ color: "#fff",  textAlign:'end', }} />
                        ))}{" "}
                    </div>
                  </Box>
                </Link>
                {subnav === val.menuName &&
                  val?.subNav?.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        to={item.route}
                        onClick={() => handleSubMenu(item.menuName)}
                        style={{ textDecoration: "none" }}
                      >
                        <Box
                          className={classNames(
                            "sidemenu",
                            `${selectmenu === item.menuName && "active"}`
                          )}
                          sx={{ py: 1, px: 2, marginLeft: 4 }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <val.icon sx={{ marginRight: 1, color: "#fff" }} />
                            <Typography sx={{ color: "#fff", fontSize:'14px' }}>
                              {item.menuName}
                            </Typography>
                          </div>
                        </Box>
                      </Link>
                    );
                  })}
              </div>
            );
          })}
        </div>
      </Box>
    </div>
  );
};

export default Sidebar;
