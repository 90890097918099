  export default function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.access_token) {
      return { 
        'Authorization': 'Bearer ' + user.access_token,
        'x-newsit-key': 'rfw6zya1BEH4nxb_jkv'
      };
    } else {
      return {};
    }
  
    // if (user && user.accessToken) {
    //   // for Node.js Express back-end
    //   return { 'x-access-token': user.accessToken };
    // } else {
    //   return {};
    // }
  }