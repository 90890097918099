import React from 'react';
import { Badge, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Custom Badge styles
const StyledBadge = styled(Badge)(({ bgcolor }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: bgcolor,       // Dynamic background color
      color: 'white',                 // Text color inside the badge
      fontSize: '1rem',               // Font size of the badge content
      width: 'auto',                  // Automatically adjust width based on content
      height: '34px',                 // Fixed height for the badge
      padding: '0 12px',              // Horizontal padding for better spacing
      borderRadius: '8px',            // Rounded corners, but keeps the box shape
      minWidth: '50px',               // Minimum width to ensure box shape
      display: 'flex',                // Center content vertically and horizontally
      alignItems: 'center',
      justifyContent: 'center',
    //   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Adds a subtle shadow effect
      border: '2px solid white',      // Adds a white border for definition
      transition: 'all 0.2s ease-in-out', // Smooth transition for dynamic changes
    },
  }));
  
//   const StyledBadge = styled(Badge)(({ bgcolor }) => ({
//     '& .MuiBadge-badge': {
//         backgroundColor: bgcolor,
//         color: 'white',
//         fontSize: '1rem',
//         width: 'auto', // Adjust width to fit larger numbers
//         height: '34px',
//         padding: '0 8px', // Add padding to accommodate larger numbers
//         borderRadius: '50%',
//         minWidth: '34px', // Ensure minimum width
//     },
// }));

const StatusDisplay = ({ status }) => {
    // Define color mapping based on the status
    const colorMap = {
        approved: '#2e7d32',
        pending: '#FF8C00',
        rejected: '#c00404',
    };

    // console.log("Status:",status);
    
    return (
        <Box display="flex" alignItems="end" justifyContent="end" gap={4} p={2} >
            {status.map((item) => (
                <Box key={item.status} display="flex" alignItems="center" gap={0.5}>
                    <StyledBadge
                        badgeContent={item.total_count}
                        bgcolor={colorMap[item.status]}
                        max={9999} // Allows displaying large numbers without "99+"
                    />
                    <Typography ml={4} textTransform="capitalize">
                        {item.status}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
};

export default StatusDisplay;
