// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_bg{
    /* background-image: url('../../assets/images/logo.png'); */
    background-color: #eeeeee;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}`, "",{"version":3,"sources":["webpack://./src/pages/login/login.css"],"names":[],"mappings":"AAAA;IACI,2DAA2D;IAC3D,yBAAyB;IACzB,4BAA4B;IAC5B,2BAA2B;IAC3B,sBAAsB;AAC1B","sourcesContent":[".login_bg{\n    /* background-image: url('../../assets/images/logo.png'); */\n    background-color: #eeeeee;\n    background-repeat: no-repeat;\n    background-position: center;\n    background-size: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
