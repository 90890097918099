import React from 'react';
import { Box, Grid, Typography, Card, Container } from '@mui/material';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import ElectricMopedOutlinedIcon from '@mui/icons-material/ElectricMopedOutlined';

const Watch = ({setLoader, show}) => {


  return (
    <Container className='content' sx={{mt: 10}} maxWidth="fluid">
       <iframe
        src="https://watch.newsit.news/"
        title="Embedded Webpage"
        width="100%" 
        height="900px" 
        style={{ border: 'none' }}
      ></iframe>
    </Container>
  );
}

export default Watch;