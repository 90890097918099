import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Autocomplete, Button, Card, Grid, TextField, Typography } from '@mui/material';
import MediaCard from '../card/Card';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAdminStats ,getcategories } from '../../slices/news';
import { useDispatch, useSelector } from 'react-redux';
import StatusDisplay from './statsDisplay';
import News from '../../pages/news/News';
import { setStatus } from '../../slices/Status';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ newsData, setNewstype, setLoader, setCity, city, cityData, setCategory, category,startDate,setStartDate,endDate,setEndDate }) {
  const [value, setValue] = React.useState(0);
  const [searchParams] = useSearchParams();
  const [stats, setstats] = React.useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const status = useSelector((state) => state.status);
  const {categories: catData} = useSelector((state) => state.news);

  // const [startDate, setStartDate] = React.useState(null); 
  // const [endDate, setEndDate] = React.useState(null); 

 console.log("newsData",newsData);

 React.useEffect(() => {
  dispatch(getcategories()) 
},[])

  // Extract the news type from the search parameters
  React.useEffect(() => {
    const searchQuery = searchParams.get('tab');
    dispatch(getAdminStats())
    .then((data) => {
      const updata = data.payload.data.data
      setstats(updata);
    })
      .catch(() => {
        setLoader(false);
      })
    if (searchQuery === 'short') {
      setValue(0);
      setNewstype("short");
    } else if (searchQuery === 'web') {
      setValue(1);
      setNewstype("web");
    }
    
  }, [searchParams, setNewstype]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setNewstype("short");
      navigate('?tab=short'); 
    } else if (newValue === 1) {
      setNewstype("web");
      navigate('?tab=web'); 
    }
  };
  const handleEdit = (newsItem) => {
    if (newsItem.type === 'short') {
      setValue(0);
      setNewstype("short");
      navigate('?tab=short'); 
    } else if (newsItem.type === 'web') {
      setValue(1);
      setNewstype("web");
      navigate('?tab=web'); 
    }
  };


//   React.useEffect(() => {
//     const StatusParams = searchParams.get('status');
//     console.log("StatusParams:",StatusParams);
    
//     switch (StatusParams) {
//         case "approved":
//             dispatch(setStatus("approved"));
//             break;
//         case "rejected":
//             dispatch(setStatus("rejected"));
//             break;
//         case "pending":
//             dispatch(setStatus("pending"));
//             break;
//         default:
//             dispatch(setStatus("pending"));
//     }
// }, [dispatch, searchParams]);

  const handleStatus = (value) => {
    console.log("Val:",value);
    dispatch(setStatus(value))
  }   

  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Short News" {...a11yProps(0)}  sx={{ textTransform: 'initial', minWidth: 0, fontWeight: 'bold' }} />
          <Tab label="Web News" {...a11yProps(1)}  sx={{ textTransform: 'initial', minWidth: 0, fontWeight: 'bold' }} />
        </Tabs>
      </Box>
      
      <CustomTabPanel value={value} index={0}>
        {/* <Box sx={{ display: 'flex',justifyContent:"space-between" ,pt:2,pb:2,alignItems:"center"}}> */}
          <Box sx={{ display: 'flex',gap: 1, mt: 2 }}>
          <Button variant={status === 'pending' ? 'contained' : 'outlined'} onClick={() => handleStatus("pending")} sx={{ borderRadius: 10 }}>Pending</Button>
          <Button variant={status === 'approved' ? 'contained' : 'outlined'} onClick={() => handleStatus("approved")} sx={{ borderRadius: 10 }}>Approved</Button>
          <Button variant={status === 'rejected' ? 'contained' : 'outlined'} onClick={() => handleStatus("rejected")} sx={{ borderRadius: 10 }}>Rejected</Button>
          </Box> 
          {/* {
            stats ? <StatusDisplay status={stats.status} /> : <></>
          } */}

        {/* </Box> */}
        <Box sx={{ display: "flex", alignItems:"center",justifyContent:"end",gap:2,marginTop:2  }}>
          {/* FilterDate */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ width: '100%',display:'flex',gap:'40px' }}>
        <DatePicker label="Start Date" value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
          renderInput={(params) => <TextField {...params} />}
        />
        <DatePicker label="End Date" value={endDate}
          onChange={(newValue) => setEndDate(newValue)}
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
    </LocalizationProvider> 

    <Box sx={{ width: '50%',display:'flex',gap:'40px',marginTop:2 }}>

          <Autocomplete
            sx={{ width: 300 }}
            options={catData}
            value={category}
            getOptionLabel={(option) => `${option.name}`}
            renderInput={(params) => <TextField {...params} sx={{ marginBottom: 2 }} label="Select Category" variant="outlined" />}
            onChange={(event, newValue) => {
              setCategory(newValue);
            }}
          />


          {/* Filters City */}
          <Autocomplete
            sx={{  width: 300 }}
            options={cityData}
            value={city}
            getOptionLabel={(option) => `${option.name}`}
            renderInput={(params) => <TextField {...params} sx={{ marginBottom: 2 }} label="Select City" variant="outlined" />}
            onChange={(event, newValue) => {
              setCity(newValue);
            }}
          />
          </Box>
        </Box>
        
        <Grid container spacing={2} sx={{ mt: 0, mb: 5 }}>
          {newsData?.map((val, index) => (
            <MediaCard status={status} setLoader={setLoader} key={index} val={val} onEdit={handleEdit}  />
          ))}
        </Grid>
      </CustomTabPanel>
     
      <CustomTabPanel value={value} index={1}>
        <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
          <Button variant={status === 'pending' ? 'contained' : 'outlined'} onClick={() => handleStatus("pending")} sx={{ borderRadius: 10 }}>Pending</Button>
          <Button variant={status === 'approved' ? 'contained' : 'outlined'} onClick={() => handleStatus("approved")} sx={{ borderRadius: 10 }}>Approved</Button>
          <Button variant={status === 'rejected' ? 'contained' : 'outlined'} onClick={() => handleStatus("rejected")} sx={{ borderRadius: 10 }}>Rejected</Button>
        </Box>

        <Box sx={{ display: "flex", alignItems:"center",justifyContent:"end",gap:2,marginTop:2 }}>
      {/* FilterDate */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
       <Box sx={{ width: '100%',display:'flex',gap:'40px' }}>
        <DatePicker label="Start Date" value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
          renderInput={(params) => <TextField {...params} />}
        />
        <DatePicker label="End Date" value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
      </Box>
    </LocalizationProvider> 


    <Box sx={{ width: '50%',display:'flex',gap:'40px',marginTop:2 }}>
          <Autocomplete
            sx={{ width: 300 }}
            options={catData}
            value={category}
            getOptionLabel={(option) => `${option.name}`}
            renderInput={(params) => <TextField {...params} sx={{ marginBottom: 2 }} label="Select Category" variant="outlined" />}
            onChange={(event, newValue) => {
              setCategory(newValue);
            }}
          />


          {/* Filters City */}
          <Autocomplete
            sx={{  width: 300 }}
            options={cityData}
            value={city}
            getOptionLabel={(option) => `${option.name}`}
            renderInput={(params) => <TextField {...params} sx={{ marginBottom: 2 }} label="Select City" variant="outlined" />}
            onChange={(event, newValue) => {
              setCity(newValue);
            }}
          />
          </Box>
        </Box>

        <Grid container spacing={2} sx={{ mt: 0, mb: 5 }}>
          {newsData?.map((val, index) => (
            <MediaCard setLoader={setLoader} key={index} val={val}  onEdit={handleEdit}  />
          ))}
        </Grid>
      </CustomTabPanel>
      
    
              <CustomTabPanel value={value} index={2}>

        <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
          <Button variant={status === 'pending' ? 'contained' : 'outlined'} onClick={() => handleStatus("pending")} sx={{ borderRadius: 10 }}>Pending</Button>
          <Button variant={status === 'approved' ? 'contained' : 'outlined'} onClick={() => handleStatus("approved")} sx={{ borderRadius: 10 }}>Approved</Button>
          <Button variant={status === 'rejected' ? 'contained' : 'outlined'} onClick={() => handleStatus("rejected")} sx={{ borderRadius: 10 }}>Rejected</Button>
        </Box>
      
        <Grid container spacing={2} sx={{ mt: 0, mb: 5 }}>
          {newsData?.map((val, index) => (
            <MediaCard setLoader={setLoader} key={index} val={val}  onEdit={handleEdit}  />
          ))}
        </Grid>
      </CustomTabPanel>
     
    </Box>
  );
}

