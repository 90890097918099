import React from 'react'
import Header from '../src/layouts/header/Header.js';
import { Outlet } from 'react-router-dom';
import Sidebar from './layouts/sidebar/Sidebar.js';

const Layout = ({show, setShow}) => {
  return (
    <div>
        <Header show={show} setShow={setShow}/>
        <Outlet/>
        <Sidebar show={show} setShow={setShow}/>
    </div>
  )
}

export default Layout;